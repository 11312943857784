import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../layouts/UserContext'
import { API, graphqlOperation } from 'aws-amplify';
import { playerSort } from '../utils';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PlayerTable from './PlayerTable';

const Available = (props) => {
    const currentState = useContext(UserContext)
    const [players, setPlayers] = React.useState();
    const [picking, setPicking] = useState();
    const [confirm, setConfirm] = useState(false);
    const readyPick = props.readyPick;
    const draft = currentState.currentDraft;

    useEffect(() => {
        const playersQuery = /* GraphQL */ `
          query ListAvailablePlayers($draftId: ID!) {
            ListAvailablePlayers(draftId: $draftId) {
              id
              firstName
              lastName
              salary
              other
            }
          }
        `;
        let mounted = true;
        async function listPlayers() {
            const av = await API.graphql(
                graphqlOperation(
                    playersQuery, { draftId: props.draftId }
                )
            );
            console.log('Available', av);
            av.data.ListAvailablePlayers.sort(playerSort);
            if (mounted) setPlayers(av.data.ListAvailablePlayers);
        }
        listPlayers();

        return () => mounted = false;

    }, [props.draftId, currentState.picksUpdated])

    if (!draft) {
        return <div>Loading ...</div>;
    }

    const ConfirmForm = (props) => {
        const [isSaving, setIsSaving] = React.useState(false);
        const [error, setError] = React.useState("");
        const makePick = async (playerId) => {
            console.log("PICKING", playerId);
            if (!readyPick?.id) {
                setError('Pick failed.  Not your pick?');
                return;
            }
            const makePickMutation = /* GraphQL */ `
              mutation MakePick($draftId: ID!, $teamId: ID!, $pickId: ID!, $playerId: ID!) {
                MakePick(draftId: $draftId, teamId: $teamId, pickId: $pickId, playerId: $playerId) {
                  id
                  round
                  num
                  isNext
                  draftId
                  teamId
                  playerId
                }
              }
            `;
            try {
                const pickResult = await API.graphql(
                    graphqlOperation(
                        makePickMutation, { draftId: props.draftId,
                            teamId: props.teamId, pickId: readyPick.id, playerId: playerId }
                    )
                );
                console.log('Make Pick', pickResult);
                setConfirm(false);
                currentState.setPicksUpdated(pickResult.data.MakePick.id);
                currentState.setAlertContents('Pick completed');
                currentState.setShowAlert(true);
            } catch (err) {
                console.log('Make Pick', err);
                setError(err.errors[0].message);
            }
            finally {
                setIsSaving(false);
            }
        }

        return <Modal show={confirm} onHide={() => setConfirm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Pick</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Please confirm you would like to pick {props.picking?.firstName} {props.picking?.lastName}.</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" type="button" onClick={() => setConfirm(false)}>Close</Button>
                    <Button variant="primary" type="button" onClick={() => makePick(props.picking.id)} disabled={isSaving}>Make Pick</Button>
                    <Alert show={error !== ""} variant="danger">{ error }</Alert>
                </Modal.Footer>
        </Modal>
    }

    const showPickBtn = readyPick && props.owned && draft?.status === 'Running';
    const extraHeaders = showPickBtn ? ['Pick'] : [];
    const extraColumns = showPickBtn ?
        [p => <Button variant="link" onClick={() => { setPicking(p); setConfirm(true) }}>Pick</Button>] : [];

    return <>
        <PlayerTable title="Available Players" players={players} extraHeaders={extraHeaders} extraColumns={extraColumns} />
        <ConfirmForm draftId={props.draftId} teamId={props.teamId} picking={picking}/>
    </>
};

export default Available;

