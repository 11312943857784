import React, { useContext, useEffect } from 'react';
import UserContext from '../layouts/UserContext'
import MainLayout from '../layouts/MainLayout'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import DataTable from '../layouts/DataTable';
import { API, graphqlOperation } from 'aws-amplify';
import { nameSort, teamSort } from '../utils';

const LandingPage = () => {
    const currentState = useContext(UserContext)
    const [teams, setTeams] = React.useState();
    const [drafts, setDrafts] = React.useState();

    useEffect(() => {
        const draftsQuery = /* GraphQL */ `
          query ListManagedDraftsForUser {
            ListManagedDraftsForUser {
              id
              name
              status
            }
          }
        `;
        async function getDrafts() {
            const tms = await API.graphql(
                graphqlOperation(draftsQuery)
            );
            console.log('User Drafts', tms);
            tms.data.ListManagedDraftsForUser?.sort(nameSort);
            setDrafts(tms.data.ListManagedDraftsForUser);
        }
        if (currentState?.user) {
            getDrafts();
        }
    }, [currentState?.user])

    useEffect(() => {
        const teamsQuery = /* GraphQL */ `
          query ListManagedTeamsForUser {
            ListManagedTeamsForUser {
              id
              draftId
              name
              draft {
                id
                name
                status
              }
            }
          }
        `;
        async function getTeams() {
            const tms = await API.graphql(
                graphqlOperation(teamsQuery)
            );
            console.log('User Teams', tms);
            tms.data.ListManagedTeamsForUser?.sort(teamSort);
            setTeams(tms.data.ListManagedTeamsForUser);
        }
        if (currentState?.user) {
            getTeams();
        }
    }, [currentState?.user])

    const draftRows = drafts?.map( (draft) => ({
        id: draft.id,
        data: [
            <a href={"#/draft/" + draft.id}>{draft.name}</a>,
        ]
    }));

    const teamHeaders = ['Draft', 'Team'];
    const teamRows = teams?.map( (team) => ({
        id: team.id,
        data: [
            <a href={"#/draft/" + team.draftId}>{team.draft.name}</a>,
            <a href={"#/draft/" + team.draftId + "/team/" + team.id}>{team.name}</a>,
        ]
    }));

    if (!currentState) {
        return <div>Loading ...</div>
    }

    return (
        <MainLayout>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>MyFantasyToolbox Draft</h1>
                    </Col>
                </Row>
                <Row><Col><hr/></Col></Row>
                <Row>
                    <Col>
                        { drafts?.length > 0 &&
                        <DataTable title="Drafts You Manage" rows={draftRows} ready={drafts}/>
                        }
                        { teams?.length > 0 &&
                        <DataTable title="Teams You Manage" headers={teamHeaders} rows={teamRows} ready={teams}/>
                        }
                        { drafts?.length === 0 && teams?.length === 0 &&
                            <>
                            <p>You currently aren't associated with any drafts.</p>
                            <p>Draft owners can invite you by setting your email address on a team in their draft.</p>
                            <p>Or, to create a new draft yourself, choose "New Draft" in the "My Drafts" dropdown above.</p>
                            </>
                        }
                    </Col>
                </Row>
            </Container>
        </MainLayout>
    )
};

export default LandingPage;


