import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../layouts/UserContext'
import { API, graphqlOperation } from 'aws-amplify';
import DataTable from '../layouts/DataTable';
import Modal from 'react-bootstrap/Modal'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import { bidSort, auctionSortEnd, validateDollars } from '../utils';

const AuctionTable = (props) => {
    const currentState = useContext(UserContext)
    const draft = currentState.currentDraft;
    const [shouldShowStartAuctionForm, setShouldShowStartAuctionForm] = useState(false);
    const [shouldShowBidForm, setShouldShowBidForm] = useState(false);
    const [shouldShowHistory, setShouldShowHistory] = useState(false);
    const [bidPlayerId, setBidPlayerId] = useState("");
    const [bidCurrentAmount, setBidCurrentAmount] = useState("");
    const [historyAuction, setHistoryAuction] = useState("");
    const [auctionFilter, setAuctionFilter] = useState("ACTIVE");

    const placeBidMutation = /* GraphQL */ `
        mutation PlaceBid($draftId: ID!, $teamId: ID!, $playerId: ID!, $amount: Float!) {
            PlaceBid(draftId: $draftId, teamId: $teamId, playerId: $playerId, amount: $amount) {
                id
                draftId
            }
        }
    `;

    if (!draft) {
        return <div>Loading ...</div>;
    }

    const userTeams = draft?.teams.filter( (t) => t.managerId === 'USER-' + currentState.user?.sub );

    const Auctions = () => {
        const [now, setNow] = useState(new Date());

        const [auctionFilterName, setAuctionFilterName] = useState("Active");
        const [auctionsToShow, setAuctionsToShow] = useState();

        const draftRunning = (draft?.status === 'Running');

        useEffect(() => {
            const interval = setInterval(() => setNow(Date.now()), 1000);
            return () => {
                clearInterval(interval);
            };
        }, []);

        useEffect(() => {
            switch(auctionFilter) {
                case "ALL":
                    setAuctionsToShow( draft?.auctions );
                    setAuctionFilterName( "All" );
                    break;
                case "ACTIVE":
                    setAuctionsToShow( draft?.auctions.filter( a => new Date(a.endTime) > now ) );
                    setAuctionFilterName( "Active" );
                    break;
                case "COMPLETE":
                    setAuctionsToShow( draft?.auctions.filter( a => new Date(a.endTime) <= now ) );
                    setAuctionFilterName( "Completed" );
                    break;
                case "WON":
                    setAuctionsToShow( draft?.auctions.filter( a => userTeams.map( t => t.id ).includes(a.teamId) ) );
                    setAuctionFilterName( "Winning / Won" );
                    break;
                case "OUTBID":
                    setAuctionsToShow( draft?.auctions.filter(
                        a => new Date(a.endTime) > now
                        && !userTeams.map( t => t.id ).includes(a.teamId)
                        && a.bids.length > 1
                        && a.bids.sort(bidSort).slice(0,-1).map(b => b.teamId).filter(t => userTeams.map( t => t.id ).includes(t)).length > 0
                    ) );
                    setAuctionFilterName( "Outbid active" );
                    break;
                default:
                    break;
            }
        }, [now]);

        const headers = ['Bid', 'Last', 'First']
            .concat(draft.playerColumns || [])
            .concat(['Amount', 'Team', 'History', 'Remaining'])

        const calcRemaining = (endTime) => {
            if ((new Date(endTime)) < now) { return "Ended"; }
            const totalSeconds = Math.round(((new Date(endTime)) - now) / 1000);
            const hours = Math.floor(totalSeconds / (60*60));
            let minutes = totalSeconds % (60*60);
            let seconds = minutes % 60;
            minutes = Math.floor(minutes / 60);
            return <div className="remaining" endtime={endTime}>{`${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}</div>;
        };

        const rows = auctionsToShow?.sort(auctionSortEnd).map( (auction) => ({
            id: auction.playerId,
            data: [
                draftRunning && (new Date(auction.endTime)) > now
                ? <Button variant="link" onClick={() => showBidForm(auction.playerId, auction.amount)}>Bid</Button>
                : "",
                auction.player.lastName,
                auction.player.firstName
            ].concat(
                draft.playerColumns.map( pc => JSON.parse(auction.player.other)[pc] || "" )
            ).concat([
                '$' + parseFloat(auction.amount).toFixed(2),
                auction.team ? <a href={"#/draft/" + draft.id + "/team/" + auction.team.id}>{auction.team.name}</a> : "",
                <Button variant="link"
                    onClick={ () => { setHistoryAuction(auction); setShouldShowHistory(true); }}>History</Button>,
                calcRemaining(auction.endTime)
            ])
        }));

        const navLinks = [
            <Nav className="mr-auto" key="1">
                { draftRunning &&
                  <Nav.Link onClick={() => setShouldShowStartAuctionForm(true)}>Start New</Nav.Link>
                }
                <NavDropdown align="end" title={auctionFilterName}>
                    <NavDropdown.Item onClick={() => setAuctionFilter("ALL")}>All</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => setAuctionFilter("ACTIVE")}>Active</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => setAuctionFilter("COMPLETE")}>Completed</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => setAuctionFilter("WON")}>Winning / Won</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => setAuctionFilter("OUTBID")}>Outbid active</NavDropdown.Item>
                </NavDropdown>
            </Nav>
        ]

        return <DataTable title="Auction List" navLinks={navLinks} headers={headers} rows={rows} ready={draft?.auctions}/>
    };

    const showBidForm = (playerId, amount) => {
        setBidPlayerId(playerId);
        setBidCurrentAmount(amount);
        setShouldShowBidForm(true);
    };

    const hideBidForm = () => {
        setBidPlayerId("");
        setShouldShowBidForm(false);
    }

    const BidForm = () => {
        const [isSaving, setIsSaving] = React.useState(false);
        const [error, setError] = React.useState([]);
        const [bidPlayerFirstName, setBidPlayerFirstName] = useState("");
        const [bidPlayerLastName, setBidPlayerLastName] = useState("");
        const [selectTeamId, setSelectTeamId] = React.useState("");
        const [bidAmount, setBidAmount] = React.useState("");

        useEffect(() => {
            const matches = bidPlayerId !== "" ? draft?.players.filter(t => t.id === bidPlayerId) : [];
            const player = matches ? matches[0] : undefined;
            if (player) {
                setBidPlayerFirstName(player.firstName);
                setBidPlayerLastName(player.lastName);
            } else {
                setBidPlayerFirstName("");
                setBidPlayerLastName("");
            }
        }, [])

        if (userTeams.length === 1 && !selectTeamId) { setSelectTeamId(userTeams[0].id); }

        const placeBid = async (event) => {
            event.preventDefault();
            setIsSaving(true);
            try {
                const errors = [];
                if (selectTeamId === "") { errors.push("Please choose a team"); }
                validateDollars(bidAmount, "Bid", errors);
                if (errors.length > 0) {
                    setError(errors);
                    return;
                }
                const result = await API.graphql(
                    graphqlOperation( placeBidMutation, {
                        draftId: draft.id, teamId: selectTeamId, playerId: bidPlayerId, amount: bidAmount
                    } )
                );
                console.log('Place Bid', result);
                hideBidForm();
                currentState.reloadDraft(draft.id);
            }
            catch (err) {
                console.log('Place Bid', err);
                setError([err.errors[0].message]);
            }
            finally {
                setIsSaving(false);
            }
        }

        return <Modal show={shouldShowBidForm} onHide={() => hideBidForm()}>
            <Form onSubmit={placeBid}>
                <Modal.Header closeButton>
                    <Modal.Title>Place Bid on {bidPlayerFirstName} {bidPlayerLastName}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    { userTeams.length > 1 &&
                        <Form.Group className="mb-3" controlId="formTeamId">
                            <Form.Label>Team</Form.Label>
                            <Form.Select size="sm" value={selectTeamId}
                                onChange={(e) => {e.preventDefault(); setSelectTeamId(e.target.value)}}>
                                <option value=""></option>
                                { userTeams.map( (team) => {
                                    return <option key={team.id} value={team.id}>{team.name}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    }

                    <Form.Group className="mb-3" controlId="formBidAmount">
                        <Form.Label>Bid</Form.Label>
                        <Form.Control type="text" value={bidAmount}
                            onChange={(e) => {e.preventDefault(); setBidAmount(e.target.value)}}/>
                        <Form.Text muted>Current high bid is ${parseFloat(bidCurrentAmount).toFixed(2)} </Form.Text>
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" type="button" onClick={() => hideBidForm()}>Close</Button>
                    <Button variant="primary" type="submit" disabled={isSaving}>Place Bid</Button>
                    { error.map( (e, idx) => <Alert key={idx} style={{ flexBasis: "100%" }} variant="danger">{ e }</Alert> )}
                </Modal.Footer>
            </Form>
        </Modal>
    }

    const StartAuctionForm = () => {
        const [isSaving, setIsSaving] = React.useState(false);
        const [error, setError] = React.useState([]);
        const [selectTeamId, setSelectTeamId] = React.useState("");
        const [selectPlayerId, setSelectPlayerId] = React.useState("");
        const [bidAmount, setBidAmount] = React.useState(parseFloat(draft?.auctionSettings.minBid).toFixed(2));

        const startAuction = async (event) => {
            event.preventDefault();
            setIsSaving(true);
            try {
                const errors = [];
                if (selectPlayerId === "") { errors.push("Please choose a player"); }
                if (selectTeamId === "") { errors.push("Please choose a team"); }
                validateDollars(bidAmount, "Bid", errors);
                if (errors.length > 0) {
                    setError(errors);
                    return;
                }
                const result = await API.graphql(
                    graphqlOperation( placeBidMutation, {
                        draftId: draft.id, teamId: selectTeamId, playerId: selectPlayerId, amount: bidAmount
                    } )
                );
                console.log('Place Bid', result);
                setShouldShowStartAuctionForm(false);
                currentState.reloadDraft(draft.id);
            }
            catch (err) {
                console.log('Place Bid', err);
                setError([err.errors[0].message]);
            }
            finally {
                setIsSaving(false);
            }
        }

        if (userTeams.length === 1 && !selectTeamId) { setSelectTeamId(userTeams[0].id); }

        return <Modal show={shouldShowStartAuctionForm} onHide={() => setShouldShowStartAuctionForm(false)}>
            <Form onSubmit={startAuction}>
                <Modal.Header closeButton>
                    <Modal.Title>Start Player Auction</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    { userTeams.length > 1 &&
                        <Form.Group className="mb-3" controlId="formTeamId">
                            <Form.Label>Team</Form.Label>
                            <Form.Select size="sm" value={selectTeamId}
                                onChange={(e) => {e.preventDefault(); setSelectTeamId(e.target.value)}}>
                                <option value=""></option>
                                { userTeams.map( (team) => {
                                    return <option key={team.id} value={team.id}>{team.name}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    }

                    <Form.Group className="mb-3" controlId="formPlayerId">
                        <Form.Label>Player</Form.Label>
                        <Form.Select size="sm" value={selectPlayerId}
                            onChange={(e) => {e.preventDefault(); setSelectPlayerId(e.target.value)}}>
                            <option value=""></option>
                            { draft?.players
                                    .filter( (player) => !player.teamId && !draft?.auctions.map( (a) => a.playerId ).includes( player.id ) )
                                    .map( (player) => {
                                return <option key={player.id} value={player.id}>{player.lastName}, {player.firstName}</option>
                            })}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBidAmount">
                        <Form.Label>Bid</Form.Label>
                        <Form.Control type="text" value={bidAmount}
                            onChange={(e) => {e.preventDefault(); setBidAmount(e.target.value)}}/>
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" type="button" onClick={() => setShouldShowStartAuctionForm(false)}>Close</Button>
                    <Button variant="primary" type="submit" disabled={isSaving}>Start Auction</Button>
                    { error.map( (e, idx) => <Alert key={idx} style={{ flexBasis: "100%" }} variant="danger">{ e }</Alert> )}
                </Modal.Footer>
            </Form>
        </Modal>
    }

    const History = () => {
        if (!historyAuction?.bids) { return null; }
        const bids = historyAuction.bids || [];
        bids.map( b => b.teamName = draft.teams.filter( t => t.id === b.teamId )[0].name );
        bids.sort(bidSort);

        const headers = ['Date', 'Team', 'Amount'];
        const rows = bids.map( bid => { return {
            id: bid.id,
            data: [
                new Date(bid.bidTime).toLocaleString(navigator.language || navigator.userLanguage),
                bid.teamName,
                '$' + bid.amount.toFixed(2)
            ]
        }});

        return <Modal show={shouldShowHistory} onHide={() => setShouldShowHistory(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{historyAuction.player.firstName + " " + historyAuction.player.lastName}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <DataTable title="Bid History" headers={headers} rows={rows}/>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" type="button" onClick={() => setShouldShowHistory(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    }
    return <>
        <Auctions/>
        <BidForm/>
        <StartAuctionForm/>
        <History/>
    </>
};

export default AuctionTable;

