import React, { useContext, useEffect } from 'react';
import UserContext from '../layouts/UserContext'
import MainLayout from '../layouts/MainLayout'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { useParams } from "react-router-dom";
import PickTable from './PickTable';
import AuctionTable from './AuctionTable';
import DataTable from '../layouts/DataTable';
import PlayerTable from './PlayerTable';
import Settings from './Settings';
import SettingsForm from './SettingsForm';
import PickStatus from './PickStatus';

const Draft = () => {
    let params = useParams();
    const currentState = useContext(UserContext)
    const draft = currentState.currentDraft;
    const setCurrentDraftId = currentState.setCurrentDraftId;
    const newDraft = (params.draftId === 'new');

    useEffect(() => {
        if (newDraft) {
            setCurrentDraftId(null);
        }
        else if (draft?.id !== params.draftId) {
            setCurrentDraftId(params.draftId);
        }
    }, [params.draftId, newDraft, draft, setCurrentDraftId])

    const TeamTable = () => {
        const headers = ['Team', 'Manager', 'Players'].concat(
            draft?.showSalary ? ['Salary'] : []
        );
        const rows = draft?.teams?.map( (team,index) => ({
            id: team.id,
            data: [
                <a href={"#/draft/" + params.draftId + "/team/" + team.id}>{team.name}</a>,
                team.manager ? team.manager.firstName + ' ' + team.manager.lastName : team.managerId,
                <div>
                    { team.rosterSize }
                    { team.injuredSize > 0 ? " (+" + team.injuredSize + " on IL)" : "" }
                    { team.pendingBids.length > 0 ? <><br/>(with {team.pendingBids.length} pending)</> : ""}
                </div>
            ].concat(
                draft?.showSalary ?
                [
                    <div>
                        ${team.salary}
                        { team.pendingBids.length > 0 ? <><br/>(+${team.pendingBidsTotal.toFixed(2)} pending)</> : ""}
                    </div>
                ]
                : []
            )
        }));
        return <DataTable title="Teams" headers={headers} rows={rows} ready={draft?.teams}/>
    }

    return (
        <MainLayout>
            {newDraft ?
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>New Draft</h1>
                    </Col>
                </Row>
                <Row><Col><hr/></Col></Row>
                <Row>
                    <Col>
                        <SettingsForm/>
                    </Col>
                </Row>
            </Container>
            :
            <Container fluid={true}>
                <Row xs={1} sm={2}>
                    <Col>
                        <h1>{draft?.name}</h1>
                    </Col>
                    <Col style={{ verticalAlign: "top"}}>
                        <PickStatus draft={draft} status={currentState.pickStatus}/>
                    </Col>
                </Row>
                { draft?.notes &&
                <>
                    <Row><Col><hr/></Col></Row>
                    <Row>
                        <Col style={{ whiteSpace: "pre-line" }}>{ draft.notes }</Col>
                    </Row>
                </>
                }
                <Row><Col><hr/></Col></Row>
                <Row>
                    <Col>
                        { draft &&
                        <Tabs defaultActiveKey={ draft.type === "Auction" ? "auctions" : "picks" } className="mb-3">
                            { draft.type === "Picks" &&
                            <Tab eventKey="picks" title="Picks">
                                <PickTable/>
                            </Tab>
                            }
                            { draft.type === "Auction" &&
                            <Tab eventKey="auctions" title="Auctions">
                                <AuctionTable/>
                            </Tab>
                            }
                            <Tab eventKey="teams" title="Teams">
                                <TeamTable/>
                            </Tab>
                            <Tab eventKey="players" title="Players">
                                <PlayerTable players={draft?.players} includeTeam={true} includeSalary={draft?.showSalary}/>
                            </Tab>
                            <Tab eventKey="settings" title="Settings">
                                <Settings/>
                            </Tab>
                        </Tabs>
                        }
                    </Col>
                </Row>
            </Container>
            }
        </MainLayout>
    )
};

export default Draft;

