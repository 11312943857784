
const nameSort = (a,b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
    if (a.name.toLowerCase() > b.name.toLowerCase()) { return  1; }
    return 0;
};

const teamSort = (a,b) => {
    if (a.draft.name.toLowerCase() < b.draft.name.toLowerCase()) { return -1; }
    if (a.draft.name.toLowerCase() > b.draft.name.toLowerCase()) { return  1; }
    if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
    if (a.name.toLowerCase() > b.name.toLowerCase()) { return  1; }
    return 0;
};

const playerSort = (a,b) => {
    if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) { return -1; }
    if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) { return  1; }
    if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) { return -1; }
    if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) { return  1; }
    return 0;
};

const pickSort = (a,b) => {
    if (a.round < b.round) { return -1; }
    if (a.round > b.round) { return  1; }
    if (a.num < b.num) { return -1; }
    if (a.num > b.num) { return  1; }
    return 0;
};

const bidSort = (a,b) => {
    if (a.bidTime < b.bidTime) { return -1; }
    if (a.bidTime > b.bidTime) { return  1; }
    return 0;
};

const auctionSortEnd = (a,b) => {
    const now = (new Date()).toISOString();
    const aEnded = (a.endTime < now);
    const bEnded = (b.endTime < now);
    if (!aEnded && !bEnded) {
        if (a.endTime < b.endTime) { return -1; }
        if (a.endTime > b.endTime) { return  1; }
        return 0;
    } else {
        if (aEnded && bEnded) {
            return playerSort(a.player,b.player);
        } else {
            return aEnded ? 1 : -1;
        }
    }
};

const duplicates = (arry) => {
    const uniqueElements = new Set(arry);
    const filteredElements = arry.filter(item => {
        if (uniqueElements.has(item)) {
            uniqueElements.delete(item);
            return null;
        } else {
            return item;
        }
    });
    return [...new Set(filteredElements)]
};

const validateDollars = (value, label, errors) => {
    const newErrors = [];
    if (!value) {
        newErrors.push(`${label} is required`);
    } else if (isNaN(value)) {
        newErrors.push(`${label} must be a number`);
    } else if (Number(value) <= 0) {
        newErrors.push(`${label} must be greater than zero`);
    } else if (Number(value) * 100.0 !== Number(value).toFixed(2) * 100.0) {
        newErrors.push(`${label} can not have more than two decimal places`);
    }
    if (newErrors.length > 0) {
        errors.push(...newErrors);
        return false
    }
    return true;
};

const validateIsPositiveInteger = (n, label, errors) => {
    const newErrors = [];
    if (!(n >>> 0 === parseFloat(n))) {
        newErrors.push(`${label} value must be a positive integer`);
    } else if (n <=0) {
        newErrors.push(`${label} value must be a positive integer`);
    }
    if (newErrors.length > 0) {
        errors.push(...newErrors);
        return false
    }
    return true;
};

export { nameSort, teamSort, playerSort, pickSort, bidSort, auctionSortEnd,
    duplicates, validateDollars, validateIsPositiveInteger };
