import StatusBar from './StatusBar'
import Footer from './Footer'

const MarketingLayout = (props) => (
    <>
    <StatusBar/>
    {props.children}
    <Footer/>
    </>
);

export default MarketingLayout;

