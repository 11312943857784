import React, { useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import UserContext from '../layouts/UserContext'
import MainLayout from '../layouts/MainLayout'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { API, graphqlOperation } from 'aws-amplify';
import CommissionerBulkUploadForm from './CommissionerBulkUploadForm';
import CommissionerInitPicksForm from './CommissionerInitPicksForm';
import CommissionerPicksForm from './CommissionerPicksForm';
import CommissionerPlayersForm from './CommissionerPlayersForm';
import CommissionerTeamsForm from './CommissionerTeamsForm';
import SettingsForm from './SettingsForm';

const Commissioner = () => {
    let params = useParams();
    const currentState = useContext(UserContext)
    const draft = currentState.currentDraft;
    const [error, setError] = React.useState("");
    const setCurrentDraftId = currentState.setCurrentDraftId;
    const userIsCommissioner = (draft?.managerId === ('USER-' + currentState.user?.sub));

    useEffect(() => {
        if (draft?.id !== params.draftId) {
            setCurrentDraftId(params.draftId);
        }
    }, [params.draftId, draft, setCurrentDraftId])

    if (draft && !userIsCommissioner) {
        return <div/>
    }

    const startDraft = async () => {
        const startDraftMutation = /* GraphQL */ `
          mutation StartDraft($draftId: ID!) {
            StartDraft(draftId: $draftId)
          }
        `;
        try {
            const result = await API.graphql(
                graphqlOperation( startDraftMutation, { draftId: draft.id } )
            );
            console.log('Start Draft', result);
            currentState.reloadDraft();
        } catch (err) {
            console.log('Start Draft', err);
            setError(err.errors[0].message);
        }
    }

    const pauseDraft = async () => {
        const pauseDraftMutation = /* GraphQL */ `
          mutation PauseDraft($draftId: ID!) {
            PauseDraft(draftId: $draftId)
          }
        `;
        try {
            const result = await API.graphql(
                graphqlOperation( pauseDraftMutation, { draftId: draft.id } )
            );
            console.log('Pause Draft', result);
            currentState.reloadDraft();
        } catch (err) {
            console.log('Pause Draft', err);
            setError(err.errors[0].message);
        }
    }

    return (
        <MainLayout>
            <Container fluid={true}>
                <Row xs={1} sm={2}>
                    <Col>
                        <h1>{draft?.name} Commissioner Page</h1>
                    </Col>
                    <Col style={{ verticalAlign: "top"}}>
                        { draft?.status === 'Running' ?
                        <Alert variant="warning" style={{ padding: "0px", marginBottom: "0" }}>
                            Draft Status: Running (no editing allowed while draft is running)
                        </Alert>
                        : 
                        <div>Draft Status: { draft?.status }</div>
                        }
                        <a href={"#/draft/" + params.draftId}>Back to Draft Page</a>
                    </Col>
                </Row>
                <Row><Col><hr/></Col></Row>
                <Row>
                    <Col>
                        <Tabs defaultActiveKey="draft" className="mb-3">
                            <Tab eventKey="draft" title="Draft">
                                <div>
                                    Status: {draft?.status}
                                </div>
                                <div><br/></div>
                                <div>
                                    { draft?.status === 'Predraft' && <Button onClick={startDraft}>Start Draft</Button> }
                                    { draft?.status === 'Running' && <Button onClick={pauseDraft}>Pause Draft</Button> }
                                    { draft?.status === 'Paused' && <Button onClick={startDraft}>Start Draft</Button> }
                                </div>
                                <Alert show={error !== ""} variant="danger">{ error }</Alert>
                            </Tab>
                            { (draft?.status === 'Predraft' && draft?.players.length === 0) &&
                            <Tab eventKey="bulk" title="Bulk">
                                <CommissionerBulkUploadForm/>
                            </Tab>
                            }
                            <Tab eventKey="teams" title="Teams">
                                <CommissionerTeamsForm/>
                            </Tab>
                            <Tab eventKey="players" title="Players">
                                <CommissionerPlayersForm/>
                            </Tab>
                            { draft?.type === "Picks" &&
                            <Tab eventKey="picks" title="Picks">
                                { draft?.teams.length === 0
                                    ?
                                    <div>Can't configure picks until teams are entered</div>
                                    : (
                                        draft?.picks?.length > 0
                                            ? <CommissionerPicksForm/>
                                            : <CommissionerInitPicksForm/>
                                    )
                                }
                            </Tab>
                            }
                            <Tab eventKey="settings" title="Settings">
                                <SettingsForm/>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </MainLayout>
    )
};

export default Commissioner;

