import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../layouts/UserContext'
import DataTable from '../layouts/DataTable';
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { API, graphqlOperation } from 'aws-amplify';

const CommissionerPicksForm = () => {
    const currentState = useContext(UserContext)
    const draft = currentState.currentDraft;

    const [show, setShow] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [editingPickId, setEditingPickId] = useState("");

    if (!draft) {
        return <div>Loading ...</div>
    }

    const showEdit = (pickId) => {
        setEditingPickId(pickId);
        setShow(true);
    };

    const hideEdit = () => {
        setEditingPickId("");
        setShow(false);
    }

    const PicksTable = () => {
        const headers = ['Pick', 'Team Owner', 'Player Chosen']
            .concat( (draft?.status === 'Predraft' || draft?.status === 'Paused') ? [''] : [] );
        const rows = draft?.picks.map( (pick, index) => ({
            id: pick.id,
            data: [
                pick.round + '.' + pick.num,
                pick.team?.name,
                pick.player ? (pick.player?.firstName + ' ' + pick.player?.lastName) : "",
            ].concat( (draft?.status === 'Predraft' || draft?.status === 'Paused') ?
                [<Button variant="link" onClick={() => showEdit(pick.id)}>Edit</Button>]
                : [] )
        }));
        return <>
            <DataTable headers={headers} rows={rows} ready={draft?.picks}/>
            { draft?.status === 'Predraft' ?
            <Button variant="link" onClick={() => setConfirm(true)}>Delete all picks</Button>
            : "" }
            <p/>
        </>
    }

    const ConfirmForm = () => {
        const [isSaving, setIsSaving] = React.useState(false);
        const [error, setError] = React.useState("");
        const resetPicks = async () => {
            try {
                const resetPicks = /* GraphQL */ `
                    mutation ResetPicks($draftId: ID!) {
                        ResetPicks(draftId: $draftId)
                    }
                `;
                const result = await API.graphql(
                    graphqlOperation( resetPicks, {
                        draftId: draft.id
                    } )
                );
                console.log('Reset picks', result);
                setConfirm(false);
                currentState.reloadDraft(draft.id);
            }
            catch (err) {
                console.log('Reset picks', err);
                setError(err.errors[0].message);
            }
            finally {
                setIsSaving(false);
            }
        };

        return <Modal show={confirm} onHide={() => setConfirm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Reset Picks</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Are you sure you want to delete all picks and start over?</p>
                    <p>Note: this action will not remove chosen players from team rosters.</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" type="button" onClick={() => setConfirm(false)}>Close</Button>
                    <Button variant="primary" type="button" onClick={() => resetPicks()} disabled={isSaving}>Reset Picks</Button>
                    <Alert show={error !== ""} variant="danger">{ error }</Alert>
                </Modal.Footer>
        </Modal>
    }

    const EditForm = () => {
        const [isSaving, setIsSaving] = React.useState(false);
        const [error, setError] = React.useState("");
        const [editingTeamId, setEditingTeamId] = useState("");
        const [editingPlayerId, setEditingPlayerId] = useState("");
        const [startedWithPlayerId, setStartedWithPlayerId] = useState("");

        useEffect(() => {
            const matches = editingPickId !== "" ? draft?.picks.filter(t => t.id === editingPickId) : [];
            const pick = matches ? matches[0] : undefined;
            if (pick) {
                setEditingTeamId(pick.teamId || "");
                setEditingPlayerId(pick.playerId || "");
                setStartedWithPlayerId(pick.playerId);
            } else {
                setEditingTeamId("");
                setEditingPlayerId("");
                setStartedWithPlayerId("");
            }
        }, [])

        const updatePick = async (event) => {
            event.preventDefault();
            try {
                setIsSaving(true);
                const updatePickMutation = /* GraphQL */ `
                    mutation UpdatePick($draftId: ID!, $pick: UpdatePickInput!) {
                        UpdatePick(draftId: $draftId, pick: $pick) {
                            id
                        }
                    }
                `;
                const result = await API.graphql(
                    graphqlOperation( updatePickMutation, {
                        draftId: draft.id,
                        pick: {
                            id: editingPickId,
                            draftId: draft.id,
                            teamId: editingTeamId,
                            playerId: editingPlayerId
                        }
                    } )
                );
                console.log('Update Pick', result);
                hideEdit();
                currentState.reloadDraft(draft.id);
            }
            catch (err) {
                console.log('Update Pick', err);
                setError(err.errors[0].message);
            }
            finally {
                setIsSaving(false);
            }
        };

        return <Modal show={show} onHide={() => hideEdit()}>
            <Form onSubmit={updatePick}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Pick</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form.Group className="mb-3" controlId="formPickId">
                        <Form.Control type="hidden" value={editingPickId}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formTeamId">
                        <Form.Label>Team Owner</Form.Label>
                        <Form.Select size="sm" value={editingTeamId}
                            onChange={(e) => {e.preventDefault(); setEditingTeamId(e.target.value)}}>
                            <option value=""></option>
                            { draft?.teams.map( (team) => {
                                return <option key={team.id} value={team.id}>{team.name}</option>
                            })}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formPlayerId">
                        <Form.Label>Player Chosen</Form.Label>
                        <Form.Select size="sm" value={editingPlayerId}
                            onChange={(e) => {e.preventDefault(); setEditingPlayerId(e.target.value)}}>
                            <option value=""></option>
                            { startedWithPlayerId
                                ?
                                    draft?.players
                                    .filter( (player) => player.id === startedWithPlayerId )
                                    .map( (player) => {
                                        return <option key={player.id} value={player.id}>{player.lastName}, {player.firstName}</option>
                                    })
                                :
                                    draft?.players
                                    .filter( (player) => player.teamId === editingTeamId || !player.teamId )
                                    .map( (player) => {
                                        return <option key={player.id} value={player.id}>{player.lastName}, {player.firstName}</option>
                                    })
                            }
                        </Form.Select>
                        { startedWithPlayerId ?
                        <Form.Text className="text-muted">
                            Note: unselecting a player will <u>not</u> remove him from the team's roster.
                        </Form.Text>
                        :
                        <Form.Text className="text-muted">
                            Note: Selecting a player will add him to the team's roster.
                        </Form.Text>
                        }
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" type="button" onClick={() => hideEdit()}>Close</Button>
                    <Button variant="primary" type="submit" disabled={isSaving}>Save changes</Button>
                    <Alert show={error !== ""} variant="danger">{ error }</Alert>
                </Modal.Footer>
            </Form>
        </Modal>
    }

    return <>
        <PicksTable/>
        <EditForm/>
        <ConfirmForm/>
    </>
};

export default CommissionerPicksForm;

