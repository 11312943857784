import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import UserContext from './UserContext'
import { AuthState } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import DraftDropdown from './DraftDropdown'
import AccountDropdown from './AccountDropdown'
import { useNavigate } from "react-router-dom";

const StatusBar = () => {
    const currentState = useContext(UserContext)
    const navigate = useNavigate();

    const onSignIn = () => {
        currentState.setUser(undefined);
        currentState.setAuthState(AuthState.SignIn);
        currentState.setInAuth(true);
    }
    const onSignUp = () => {
        currentState.setUser(undefined);
        currentState.setAuthState(AuthState.SignUp);
        currentState.setInAuth(true);
    }
    const onSignOut = () => {
        Auth.signOut();
        currentState.setUser(undefined);
        currentState.setAuthState(AuthState.SignedOut);
        currentState.setInAuth(false);
        navigate('/');
    }

    const LeftSide = () => {
        if (currentState.user) {
            return (
                <Nav className="me-auto">
                    <DraftDropdown/>
                </Nav>
            )
        } else {
            return (
                <Nav className="me-auto">
                </Nav>
            )
        }
    }

    const RightSide = () => {
        if (currentState.user) {
            return (
                <Nav>
                    <AccountDropdown onSignOut={onSignOut}/>
                </Nav>
            )
        }
        else {
            return (
                <Nav>
                    <Nav.Link href="#/auth" onClick={onSignIn}>Sign In</Nav.Link>
                    <Nav.Link href="#/auth" onClick={onSignUp}>Sign Up</Nav.Link>
                </Nav>
            )
        }
    }

    return (
        <Navbar bg="light" expand="sm">
            <Container fluid={true}>
                <Navbar.Brand href="/">{currentState.applicationName}</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <LeftSide/>
                    <RightSide/>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
};

export default StatusBar;

