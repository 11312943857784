import React, { useContext, useEffect, useState, useReducer } from 'react';
import UserContext from '../layouts/UserContext'
import DataTable from '../layouts/DataTable';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import { API, graphqlOperation } from 'aws-amplify';

const CommissionerPlayersForm = () => {
    const currentState = useContext(UserContext)
    const draft = currentState.currentDraft;

    const [show, setShow] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [editingPlayerId, setEditingPlayerId] = useState("");
    const [deletingPlayerId, setDeletingPlayerId] = useState("");

    if (!draft) {
        return <div>Loading ...</div>
    }

    const showConfirm = (playerId) => {
        setDeletingPlayerId(playerId);
        setConfirm(true);
    };

    const hideConfirm = () => {
        setDeletingPlayerId("");
        setConfirm(false);
    }

    const showEdit = (playerId) => {
        setEditingPlayerId(playerId);
        setShow(true);
    };

    const hideEdit = () => {
        setEditingPlayerId("");
        setShow(false);
    }

    const PlayerTable = () => {
        const headers = ['Last', 'First', 'Team']
            .concat(draft.showSalary ? ['Salary'] : [])
            .concat(draft.playerColumns || [])
            .concat(
                (draft?.status === 'Predraft' || draft?.status === 'Paused') ?
                [<Button variant="link" onClick={() => showEdit("NEW")}>Create New</Button>]
                : []
            );
        const rows = draft.players?.map( (player) => ({
            id: player.id,
            data: [
                player.lastName + (player.injured ? " (IL)" : ""),
                player.firstName,
                player.team?.name
            ].concat(
                draft.showSalary ? ['$' + parseFloat(player.salary).toFixed(2)] : []
            ).concat(
                draft.playerColumns.map( pc => JSON.parse(player.other)[pc] || "" )
            ).concat(
                (draft?.status === 'Predraft' || draft?.status === 'Paused') ?
                [
                <>
                <Button variant="link" onClick={() => showEdit(player.id)}>Edit</Button>
                {" | "}
                <Button variant="link" onClick={() => showConfirm(player.id)}>Delete</Button>
                </>
                ] : []
            )
        }));
        return <DataTable headers={headers} rows={rows} ready={draft.players}/>
    }

    const ConfirmForm = () => {
        const [isSaving, setIsSaving] = React.useState(false);
        const [error, setError] = React.useState("");
        const deletePlayer = async () => {
            try {
                const deletePlayerMutation = /* GraphQL */ `
                    mutation DeletePlayer($draftId: ID!, $playerId: ID!) {
                        DeletePlayer(draftId: $draftId, playerId: $playerId)
                    }
                `;
                const result = await API.graphql(
                    graphqlOperation( deletePlayerMutation, {
                        draftId: draft.id, playerId: deletingPlayerId
                    } )
                );
                console.log('Delete Player', result);
                hideConfirm();
                currentState.reloadDraft(draft.id);
            }
            catch (err) {
                console.log('Delete Player', err);
                setError(err.errors[0].message);
            }
            finally {
                setIsSaving(false);
            }
        };

        return <Modal show={confirm} onHide={() => hideConfirm()}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete Player</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Are you sure you want to delete this player?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" type="button" onClick={() => hideConfirm()}>Close</Button>
                    <Button variant="primary" type="button" onClick={() => deletePlayer()} disabled={isSaving}>Delete</Button>
                    <Alert show={error !== ""} variant="danger">{ error }</Alert>
                </Modal.Footer>
        </Modal>
    }

    const EditForm = () => {
        const playerOtherReducer  = (state, event) => {
            if (event.reset) { return event.data; }
            const key = event.target.id.replace("formPlayer", "").replace("_", " ");
            return { ...state, [key]: event.target.value };
        }
        const [isSaving, setIsSaving] = React.useState(false);
        const [error, setError] = React.useState("");
        const [editingPlayerFirstName, setEditingPlayerFirstName] = useState("");
        const [editingPlayerLastName, setEditingPlayerLastName] = useState("");
        const [editingPlayerInjured, setEditingPlayerInjured] = useState(false);
        const [editingPlayerSalary, setEditingPlayerSalary] = useState("");
        const [editingPlayerTeamId, setEditingPlayerTeamId] = useState("");
        const [editingPlayerOther, setEditingPlayerOther] = useReducer(playerOtherReducer, {});

        useEffect(() => {
            const matches = editingPlayerId !== "" ? draft?.players.filter(t => t.id === editingPlayerId) : [];
            const player = matches ? matches[0] : undefined;
            if (player) {
                setEditingPlayerFirstName(player.firstName);
                setEditingPlayerLastName(player.lastName);
                setEditingPlayerInjured(player.injured);
                setEditingPlayerSalary((player.salary || 0).toFixed(2));
                setEditingPlayerTeamId(player.teamId);
                setEditingPlayerOther({reset: true, data: JSON.parse(player.other)});
            } else {
                setEditingPlayerFirstName("");
                setEditingPlayerInjured(false);
                setEditingPlayerSalary("");
                setEditingPlayerTeamId("");
                setEditingPlayerOther({reset: true, data: {}});
            }
        }, [])

        const updatePlayer = async (event) => {
            event.preventDefault();
            try {
                setIsSaving(true);
                const updatedPlayer = {
                    draftId: draft.id,
                    firstName: editingPlayerFirstName,
                    lastName: editingPlayerLastName,
                    teamId: editingPlayerTeamId,
                    other: JSON.stringify(editingPlayerOther),
                }
                updatedPlayer.injured = editingPlayerInjured;
                if (draft.showSalary) {
                    updatedPlayer.salary = editingPlayerSalary || "0";
                }
                if (editingPlayerId === "NEW") {
                    const createPlayer = /* GraphQL */ `
                        mutation CreatePlayer($draftId: ID!, $player: CreatePlayerInput!) {
                            CreatePlayer(draftId: $draftId, player: $player) {
                                id
                            }
                        }
                    `;
                    const result = await API.graphql(
                        graphqlOperation( createPlayer, {
                            draftId: draft.id,
                            player: updatedPlayer
                        } )
                    );
                    console.log('New Player', result);
                } else {
                    const updatePlayer = /* GraphQL */ `
                        mutation UpdatePlayer($draftId: ID!, $player: UpdatePlayerInput!) {
                            UpdatePlayer(draftId: $draftId, player: $player) {
                                id
                            }
                        }
                    `;
                    updatedPlayer.id = editingPlayerId;
                    const result = await API.graphql(
                        graphqlOperation( updatePlayer, {
                            draftId: draft.id,
                            player: updatedPlayer
                        } )
                    );
                    console.log('Update Player', result);
                }
                hideEdit();
                currentState.reloadDraft(draft.id);
            }
            catch (err) {
                console.log('Update Player', err);
                setError(err.errors[0].message);
            }
            finally {
                setIsSaving(false);
            }
        };

        return <Modal show={show} onHide={() => hideEdit()}>
            <Form onSubmit={updatePlayer}>
                <Modal.Header closeButton>
                    <Modal.Title>{ editingPlayerId === "NEW" ? "New Player" : "Edit Player" }</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form.Group className="mb-3" controlId="formPlayerId">
                        <Form.Control type="hidden" value={editingPlayerId}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formPlayerFirstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" value={editingPlayerFirstName}
                            onChange={(e) => {e.preventDefault(); setEditingPlayerFirstName(e.target.value)}}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formPlayerLastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" value={editingPlayerLastName}
                            onChange={(e) => {e.preventDefault(); setEditingPlayerLastName(e.target.value)}}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formPlayerInjured">
                        <Form.Label>Injured</Form.Label>
                        <Form.Check type="switch"
                            checked={editingPlayerInjured}
                            onChange={(e) => {e.preventDefault(); setEditingPlayerInjured(e.target.checked)}}
                            label="When selected, this player will not take up a roster slot"/>
                    </Form.Group>

                    { draft.showSalary &&
                    <Form.Group className="mb-3" controlId="formPlayerSalary">
                        <Form.Label>Salary</Form.Label>
                        <Form.Control type="text" value={editingPlayerSalary}
                            onChange={(e) => {e.preventDefault(); setEditingPlayerSalary(e.target.value)}}/>
                    </Form.Group>
                    }

                    <Form.Group className="mb-3" controlId="formPlayerTeamId">
                        <Form.Label>Team</Form.Label>
                        <Form.Select size="sm" value={editingPlayerTeamId}
                            onChange={(e) => {e.preventDefault(); setEditingPlayerTeamId(e.target.value)}}>
                            <option value=""></option>
                            { draft?.teams.map( (team) => {
                                return <option key={team.id} value={team.id}>{team.name}</option>
                            })}
                        </Form.Select>
                    </Form.Group>

                    { draft.playerColumns.map( pc => {
                        const formId = "formPlayer" + pc.replace(' ', '_');
                        const label = pc;
                        const value = editingPlayerOther[pc] || "";
                        return <Form.Group key={pc} className="mb-3" controlId={formId}>
                            <Form.Label>{label}</Form.Label>
                            <Form.Control type="text" value={value}
                                onChange={(e) => {e.preventDefault(); setEditingPlayerOther(e)}}/>
                        </Form.Group>
                    })}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" type="button" onClick={() => hideEdit()}>Close</Button>
                    <Button variant="primary" type="submit" disabled={isSaving}>Save changes</Button>
                    <Alert show={error !== ""} variant="danger">{ error }</Alert>
                </Modal.Footer>
            </Form>
        </Modal>
    }

    return <>
        <PlayerTable/>
        <EditForm/>
        <ConfirmForm/>
    </>
};

export default CommissionerPlayersForm;

