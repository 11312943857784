import React, { useContext, useEffect } from 'react';
import UserContext from '../layouts/UserContext'
import { useParams } from "react-router-dom";
import { API, graphqlOperation } from 'aws-amplify';
import { pickSort } from '../utils';
import DataTable from '../layouts/DataTable';

const PickTable = (props) => {
    const currentState = useContext(UserContext)
    const draft = currentState.currentDraft;
    const [picks, setPicks] = React.useState();
    let params = useParams();
    const teamId = props.teamId;
    const setReadyPick = props.setReadyPick;
    const calculatePickStatus = currentState.calculatePickStatus;

    useEffect(() => {
        const picksQuery = /* GraphQL */ `
          query ListPicks($draftId: ID!) {
            ListPicks(draftId: $draftId) {
              id
              round
              num
              isNext
              teamId
              team {
                id
                name
              }
              playerId
              player {
                id
                firstName
                lastName
              }
            }
          }
        `;
        async function listPicks() {
            let picks = await API.graphql(
                graphqlOperation(picksQuery, {draftId: params.draftId})
            );
            console.log('Picks', picks);
            calculatePickStatus(picks.data.ListPicks);
            if (teamId) {
                picks.data.ListPicks = picks.data.ListPicks.filter(pick => pick.teamId === teamId);
            }
            picks.data.ListPicks.sort(pickSort);
            const nextPick = picks.data.ListPicks.filter( pick => pick.isNext );
            console.log("Next Pick", nextPick);
            if (teamId && nextPick.length === 1) {
                setReadyPick(nextPick[0]);
            } else {
                setReadyPick && setReadyPick(undefined);
            }
            setPicks(picks.data.ListPicks);
        }
        listPicks();

    }, [params.draftId, teamId, currentState.picksUpdated, calculatePickStatus, setReadyPick])

    const headers = ['Pick', 'Team', 'Player'];

    const nextLink = (pick) => {
        if (params.teamId) {
            if (props.owned && draft?.status === 'Running') {
                return "<Pick a player below>";
            } else {
                return "<On the clock>";
            }
        } else {
            return <a href={"#/draft/" + params.draftId + "/team/" + pick.teamId}>Next</a>;
        }
    };

    const rows = picks?.map( (pick, index) => ({
        id: pick.id,
        data: [
            pick.round + '.' + pick.num,
            <a href={"#/draft/" + params.draftId + "/team/" + pick.teamId}>{pick.team?.name}</a>,
            pick.player
            ? (pick.player?.firstName + ' ' + pick.player?.lastName)
            : (pick.isNext ? nextLink(pick) : "")
        ]
    }));

    return (
        <div>
            <DataTable title="Picks" headers={headers} rows={rows} ready={picks} hideCount={true}/>
        </div>
    )
};

export default PickTable;


