import React, { useContext } from 'react';
import UserContext from '../layouts/UserContext'
import { AuthState } from '@aws-amplify/ui-components';
import MarketingLayout from '../layouts/MarketingLayout'
import Button from 'react-bootstrap/Button'

const HomePage = () => {
    const currentState = useContext(UserContext)

    const signup = () => {
        currentState.setUser(undefined);
        currentState.setAuthState(AuthState.SignUp);
        currentState.setInAuth(true);
    };

    return (
        <MarketingLayout>
            <header className="masthead">
                <div className="container position-relative">
                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <div className="text-center text-white">
                                <h1 className="mb-2">Superpower your fantasy draft with MyFantasyToolbox</h1>
                                <br/>
                                <Button variant="primary" href="/#/auth" onClick={signup}>Sign Up Now!</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section className="features-icons bg-light text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                                <div className="features-icons-icon d-flex"><i className="bi-trophy m-auto text-primary"></i></div>
                                <h3>Any Sport</h3>
                                <p className="lead mb-0">Baseball, football, basketball, or whatever.  We support it all.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                                <div className="features-icons-icon d-flex"><i className="bi-tools m-auto text-primary"></i></div>
                                <h3>Customizable</h3>
                                <p className="lead mb-0">Upload your league's player pool, including supporting data of your choice</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="features-icons-item mx-auto mb-0 mb-lg-3">
                                <div className="features-icons-icon d-flex"><i className="bi-sunrise m-auto text-primary"></i></div>
                                <h3>Coming Soon</h3>
                                <p className="lead mb-0">Auction drafts and more...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            { //false &&
            //<section className="showcase">
            //    <div className="container-fluid p-0">
            //        <div className="row g-0">
            //            <div className="col-lg-6 order-lg-2 text-white showcase-img" style={{ backgroundImage: Showcase }}></div>
            //            <div className="col-lg-6 order-lg-1 my-auto showcase-text">
            //                <h2>Any Kind of Draft</h2>
            //                <p className="lead mb-0">MyFantasyToolbox is the tool to use for any kind of draft.</p>
            //            </div>
            //        </div>
            //        <div className="row g-0">
            //            <div className="col-lg-6 text-white showcase-img" style={{ backgroundImage: `Showcase` }}></div>
            //            <div className="col-lg-6 my-auto showcase-text">
            //                <h2>Updated For Bootstrap 5</h2>
            //                <p className="lead mb-0">Newly improved, and full of great utility classNamees, Bootstrap 5 is leading the way in mobile responsive web development! All of the themes on Start Bootstrap are now using Bootstrap 5!</p>
            //            </div>
            //        </div>
            //        <div className="row g-0">
            //            <div className="col-lg-6 order-lg-2 text-white showcase-img" style={{ backgroundImage: `url{$Showcase}` }}></div>
            //            <div className="col-lg-6 order-lg-1 my-auto showcase-text">
            //                <h2>Easy to Use & Customize</h2>
            //                <p className="lead mb-0">Landing Page is just HTML and CSS with a splash of SCSS for users who demand some deeper customization options. Out of the box, just add your content and images, and your new landing page will be ready to go!</p>
            //            </div>
            //        </div>
            //    </div>
            //</section>
            }
            { //false &&
            //<section className="testimonials text-center bg-light">
            //    <div className="container">
            //        <h2 className="mb-5">What people are saying...</h2>
            //        <div className="row">
            //            <div className="col-lg-4">
            //                <div className="testimonial-item mx-auto mb-5 mb-lg-0">
            //                    <img className="img-fluid rounded-circle mb-3" src={ Showcase } alt="..." />
            //                    <h5>Margaret E.</h5>
            //                    <p className="font-weight-light mb-0">"This is fantastic! Thanks so much guys!"</p>
            //                </div>
            //            </div>
            //            <div className="col-lg-4">
            //                <div className="testimonial-item mx-auto mb-5 mb-lg-0">
            //                    <img className="img-fluid rounded-circle mb-3" src={ Showcase } alt="..." />
            //                    <h5>Fred S.</h5>
            //                    <p className="font-weight-light mb-0">"Bootstrap is amazing. I've been using it to create lots of super nice landing pages."</p>
            //                </div>
            //            </div>
            //            <div className="col-lg-4">
            //                <div className="testimonial-item mx-auto mb-5 mb-lg-0">
            //                    <img className="img-fluid rounded-circle mb-3" src={ Showcase } alt="..." />
            //                    <h5>Sarah W.</h5>
            //                    <p className="font-weight-light mb-0">"Thanks so much for making these free resources available to us!"</p>
            //                </div>
            //            </div>
            //        </div>
            //    </div>
            //</section>
            }
            <section className="call-to-action text-white text-center" id="signup">
                <div className="container position-relative">
                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <h2 className="mb-4">Ready to get started?</h2>
                                <Button variant="primary" href="/#/auth" onClick={signup}>Sign Up Now!</Button>
                        </div>
                    </div>
                </div>
            </section>
        </MarketingLayout>
    )
};

export default HomePage;

