import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../layouts/UserContext'
import DataTable from '../layouts/DataTable';
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { API, graphqlOperation } from 'aws-amplify';

const CommissionerTeamsForm = () => {
    const currentState = useContext(UserContext)
    const draft = currentState.currentDraft;

    const [show, setShow] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [editingTeamId, setEditingTeamId] = useState("");
    const [deletingTeamId, setDeletingTeamId] = useState("");

    if (!draft) {
        return <div>Loading ...</div>
    }

    const showConfirm = (teamId) => {
        setDeletingTeamId(teamId);
        setConfirm(true);
    };

    const hideConfirm = () => {
        setDeletingTeamId("");
        setConfirm(false);
    }

    const showEdit = (teamId) => {
        setEditingTeamId(teamId);
        setShow(true);
    };

    const hideEdit = () => {
        setEditingTeamId("");
        setShow(false);
    }

    const TeamTable = () => {
        const headers = [
            'Team Name',
            'Manager Email',
            ].concat(
                draft?.status === 'Predraft' ?
                    [<Button variant="link" onClick={() => showEdit("NEW")}>Create New</Button>]
                : []
            );
        const rows = draft?.teams?.map( (team,index) => ({
            id: team.id,
            data: [
                team.name,
                team.manager ? team.manager.emailAddress : team.managerId
            ].concat(
                ( draft?.status === 'Predraft' || draft?.status === 'Paused' ) ? [
                    <>
                    <Button variant="link" onClick={() => showEdit(team.id)}>Edit</Button>
                    { draft?.status === 'Predraft' ?
                        <>{" | "}<Button variant="link" onClick={() => showConfirm(team.id)}>Delete</Button></>
                    : "" }
                    </>
                ] : []
            )
        }));
        return <DataTable headers={headers} rows={rows} ready={draft?.teams}/>
    }

    const ConfirmForm = () => {
        const [isSaving, setIsSaving] = React.useState(false);
        const [error, setError] = React.useState("");
        const deleteTeam = async () => {
            try {
                const deleteTeamMutation = /* GraphQL */ `
                    mutation DeleteTeam($draftId: ID!, $teamId: ID!) {
                        DeleteTeam(draftId: $draftId, teamId: $teamId)
                    }
                `;
                const result = await API.graphql(
                    graphqlOperation( deleteTeamMutation, {
                        draftId: draft.id, teamId: deletingTeamId
                    } )
                );
                console.log('Delete Team', result);
                hideConfirm();
                currentState.reloadDraft(draft.id);
            }
            catch (err) {
                console.log('Delete Team', err);
                setError(err.errors[0].message);
            }
            finally {
                setIsSaving(false);
            }
        };

        return <Modal show={confirm} onHide={() => hideConfirm()}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete Team</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Are you sure you want to delete this team?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" type="button" onClick={() => hideConfirm()}>Close</Button>
                    <Button variant="primary" type="button" onClick={() => deleteTeam()} disabled={isSaving}>Delete</Button>
                    <Alert show={error !== ""} variant="danger">{ error }</Alert>
                </Modal.Footer>
        </Modal>
    }

    const EditForm = () => {
        const [isSaving, setIsSaving] = React.useState(false);
        const [error, setError] = React.useState("");
        const [editingTeamName, setEditingTeamName] = useState("");
        const [editingTeamEmail, setEditingTeamEmail] = useState("");

        useEffect(() => {
            const matches = editingTeamId !== "" ? draft?.teams.filter(t => t.id === editingTeamId) : [];
            const team = matches ? matches[0] : undefined;
            if (team) {
                setEditingTeamName(team.name);
                setEditingTeamEmail(team.manager ? team.manager.emailAddress : team.managerId);
            } else {
                setEditingTeamName("");
                setEditingTeamEmail("");
            }
        }, [])

        const updateTeam = async (event) => {
            event.preventDefault();
            try {
                setIsSaving(true);
                if (editingTeamId === "NEW") {
                    const createTeam = /* GraphQL */ `
                        mutation CreateTeam($draftId: ID!, $team: CreateTeamInput!) {
                            CreateTeam(draftId: $draftId, team: $team) {
                                id
                            }
                        }
                    `;
                    const result = await API.graphql(
                        graphqlOperation( createTeam, {
                            draftId: draft.id,
                            team: {
                                name: editingTeamName,
                                draftId: draft.id,
                                managerId: editingTeamEmail
                            }
                        } )
                    );
                    console.log('New Team', result);
                } else {
                    const updateTeam = /* GraphQL */ `
                        mutation UpdateTeam($draftId: ID!, $team: UpdateTeamInput!) {
                            UpdateTeam(draftId: $draftId, team: $team) {
                                id
                            }
                        }
                    `;
                    const result = await API.graphql(
                        graphqlOperation( updateTeam, {
                            draftId: draft.id,
                            team: {
                                id: editingTeamId,
                                name: editingTeamName,
                                draftId: draft.id,
                                managerId: editingTeamEmail
                            }
                        } )
                    );
                    console.log('Update Team', result);
                }
                hideEdit();
                currentState.reloadDraft(draft.id);
            }
            catch (err) {
                console.log('Update Team', err);
                setError(err.errors[0].message);
            }
            finally {
                setIsSaving(false);
            }
        };

        return <Modal show={show} onHide={() => hideEdit()}>
            <Form onSubmit={updateTeam}>
                <Modal.Header closeButton>
                    <Modal.Title>{ editingTeamId === "NEW" ? "New Team" : "Edit Team" }</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form.Group className="mb-3" controlId="formTeamId">
                        <Form.Control type="hidden" value={editingTeamId}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formTeamName">
                        <Form.Label>Team Name</Form.Label>
                        <Form.Control type="text" value={editingTeamName}
                            onChange={(e) => {e.preventDefault(); setEditingTeamName(e.target.value)}}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formTeamManagerEmail">
                        <Form.Label>Manager Email</Form.Label>
                        <Form.Control type="email" value={editingTeamEmail}
                            onChange={(e) => {e.preventDefault(); setEditingTeamEmail(e.target.value)}}/>
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" type="button" onClick={() => hideEdit()}>Close</Button>
                    <Button variant="primary" type="submit" disabled={isSaving}>Save changes</Button>
                    <Alert show={error !== ""} variant="danger">{ error }</Alert>
                </Modal.Footer>
            </Form>
        </Modal>
    }

    return <>
        <TeamTable/>
        <EditForm/>
        <ConfirmForm/>
    </>
};

export default CommissionerTeamsForm;

