import React, { useContext } from 'react';
import { useParams } from "react-router-dom";
import UserContext from '../layouts/UserContext'

const Settings = () => {
    let params = useParams();
    const currentState = useContext(UserContext)
    const draft = currentState.currentDraft;
    const userIsCommissioner = (draft?.managerId === ('USER-' + currentState.user?.sub));

    return <>
        <div>Commissioner: {draft?.manager ? (draft.manager.firstName + ' ' + draft.manager.lastName) : draft?.managerId}</div>
        <div>Type: {draft?.type}</div>
        <div>Status: {draft?.status}</div>
        { draft?.type === "Auction"
            && <div>Salary Cap: ${draft?.auctionSettings.salaryCap}</div>
        }
        { draft?.type === "Auction"
            && <div>Max Roster Size: {draft?.auctionSettings.maxRosterSize}</div>
        }
        { draft?.type === "Auction"
            && <div>Minimum Bid: ${draft?.auctionSettings.minBid}</div>
        }
        { draft?.type === "Auction"
            && <div>Maximum Bid: ${draft?.auctionSettings.maxBid}</div>
        }
        { draft?.type === "Auction"
            && <div>Minimum Outbid Amount: ${draft?.auctionSettings.outBid}</div>
        }
        { draft?.type === "Auction"
            && <div>Bid Multiple: ${draft?.auctionSettings.multiple}</div>
        }
        { draft?.type === "Auction"
            && <div>Reset Hours: {draft?.auctionSettings.resetHours}</div>
        }
        {
            userIsCommissioner &&
            <a href={"#/draft/" + params.draftId + "/commissioner"}>Edit Draft Settings</a>
        }
    </>;
};

export default Settings;

