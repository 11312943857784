import React, { useContext, useReducer, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import UserContext from '../layouts/UserContext'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { API, graphqlOperation } from 'aws-amplify';
import { validateIsPositiveInteger, validateDollars } from "../utils";

const SettingsForm = () => {
    const currentState = useContext(UserContext)
    const draft = currentState.currentDraft;
    const navigate = useNavigate();

    const formReducer = (state, event) => {
        if (event.initial) { return event.initial; }
        if (event.target.id.startsWith('formDraft_auction_')) {
            if (!state.auctionSettings) { state.auctionSettings = {} }
            return { ...state,
                auctionSettings:
                { ...state.auctionSettings, [event.target.id.replace('formDraft_auction_', '')]: event.target.value }
            }
        }
        return { ...state, [event.target.id.replace('formDraft_', '')]: event.target.value }
    }

    const [formData, setFormData] = useReducer(formReducer, {});
    const [error, setError] = React.useState([]);
    const [user, setUser] = useState();

    useEffect( () => {
        const initData = {
            name: draft?.name,
            notes: draft?.notes,
            type: draft?.type,
        }
        if (draft?.type === 'Auction') { initData.auctionSettings = draft.auctionSettings; }
        setFormData({ initial: initData });
    }, [draft]);

    useEffect( () => {
        setError([]);
    }, [formData]);

    useEffect(() => {
        const getUserSettingsQuery = /* GraphQL */ `
            query GetUserSettings {
                GetUserSettings {
                    id,
                    newDraftCredits
                }
            }
        `;
        async function getUserSettings() {
            try {
                const getResult = await API.graphql(
                    graphqlOperation( getUserSettingsQuery, {} )
                );
                console.log('Get User Settings', getResult);
                setUser(getResult.data.GetUserSettings);
            }
            catch (err) {
                console.log('Get User Settings', err);
                setError(err.errors[0].message);
            }
        }
        getUserSettings();
    }, [])

    const validate = () => {
        const errors = [];
        if (!formData.name?.trim()) {
            errors.push("Draft name is required");
        }
        if (!formData.type) {
            errors.push("Draft type is required");
        }
        if (formData.type === 'Auction') {
            validateDollars( formData.auctionSettings?.salaryCap, "Salary cap", errors );
            validateIsPositiveInteger( formData.auctionSettings?.maxRosterSize, "Max roster size", errors );

            const minValid = validateDollars( formData.auctionSettings?.minBid, "Minimum bid", errors );
            const maxValid = validateDollars( formData.auctionSettings?.maxBid, "Maximum bid", errors );
            if (minValid && maxValid && Number(formData.auctionSettings?.maxBid) < Number(formData.auctionSettings?.minBid)) {
                errors.push("Maximum bid must be greater than the minimum bid");
            }

            validateDollars( formData.auctionSettings?.outBid, "Minimum outbid amount", errors );
            validateDollars( formData.auctionSettings?.multiple, "Bid multiple", errors );
            validateIsPositiveInteger( formData.auctionSettings?.resetHours, "Reset hours", errors );
        }
        setError(errors);
        return errors.length === 0;
    };

    const saveDraftSettings = async (event) => {
        event.preventDefault();
        if (!validate()) {
            return;
        }
        const newDraftMutation = /* GraphQL */ `
          mutation CreateDraft($draft: CreateDraftInput!) {
            CreateDraft(draft: $draft) {
              id
              name
              status
              managerId
            }
          }
        `;
        const updateDraftMutation = /* GraphQL */ `
          mutation UpdateDraft($draftId: ID!, $draft: UpdateDraftInput!) {
            UpdateDraft(draftId: $draftId, draft: $draft) {
              id
              name
              status
              managerId
            }
          }
        `;
        console.log("FD", formData);
        try {
            const result = await API.graphql(
                draft
                ? graphqlOperation( updateDraftMutation, {
                    draftId: draft.id,
                    draft: {
                        id: draft.id,
                        name: formData.name,
                        notes: formData.notes,
                        auctionSettings: formData.auctionSettings
                    }
                })
                : graphqlOperation( newDraftMutation, {draft: formData} )
            );
            console.log('Save Draft', result);
            const id = draft ? result.data.UpdateDraft.id : result.data.CreateDraft.id;
            currentState.reloadDrafts();
            currentState.setCurrentDraftId(id);
            currentState.setAlertContents('Saved successfully.');
            currentState.setShowAlert(true);
            navigate("/draft/" + id + "/commissioner");
        } catch (err) {
            console.log('Save Draft', err);
            setError(err.errors.map( (e) => e.message ));
        }
    }

    const newForm = <Form style={{ maxWidth: "500px" }} onSubmit={saveDraftSettings}>
        <Container>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="formDraft_name">
                        <Form.Label>Draft Name</Form.Label>
                        <Form.Control placeholder="Enter name for the draft"
                            value={formData.name || ""} onChange={setFormData}/>
                    </Form.Group>
                </Col>
            </Row>
            { !draft &&
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="formDraft_type">
                        <Form.Label>Draft Type</Form.Label>
                        <Form.Control as="select"
                            value={formData.type || ""} onChange={setFormData}>
                            <option value="">Choose the type of draft</option>
                            <option value="Auction">Auction</option>
                            <option value="Picks">Picks</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            }
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="formDraft_notes">
                        <Form.Label>Draft Notes / Description / Instructions</Form.Label>
                        <Form.Control placeholder="Enter any text you want to show to draft participants"
                             as="textarea" value={formData.notes || ""} onChange={setFormData}/>
                    </Form.Group>
                </Col>
            </Row>
            { formData?.type === 'Auction' &&
            <Row>
                <Col xs={12} sm={6}>
                    <Form.Group className="mb-3" controlId="formDraft_auction_salaryCap">
                        <Form.Label>Salary Cap</Form.Label>
                        <Form.Control placeholder="Cap $$"
                            value={formData.auctionSettings?.salaryCap || ""} onChange={setFormData}/>
                    </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                    <Form.Group className="mb-3" controlId="formDraft_auction_maxRosterSize">
                        <Form.Label>Max Roster Size</Form.Label>
                        <Form.Control placeholder="Roster Size"
                            value={formData.auctionSettings?.maxRosterSize || ""} onChange={setFormData}/>
                    </Form.Group>
                </Col>
            </Row>
            }
            { formData?.type === 'Auction' &&
            <Row>
                <Col xs={12} sm={6}>
                    <Form.Group className="mb-3" controlId="formDraft_auction_minBid">
                        <Form.Label>Minimum Bid</Form.Label>
                        <Form.Control placeholder="Min $$"
                            value={formData.auctionSettings?.minBid || ""} onChange={setFormData}/>
                    </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                    <Form.Group className="mb-3" controlId="formDraft_auction_maxBid">
                        <Form.Label>Maximum Bid</Form.Label>
                        <Form.Control placeholder="Max $$"
                            value={formData.auctionSettings?.maxBid || ""} onChange={setFormData}/>
                    </Form.Group>
                </Col>
            </Row>
            }
            { formData?.type === 'Auction' &&
            <Row>
                <Col xs={12} sm={6}>
                    <Form.Group className="mb-3" controlId="formDraft_auction_outBid">
                        <Form.Label>Minimum Outbid Amount</Form.Label>
                        <Form.Control placeholder="Outbid $$"
                            value={formData.auctionSettings?.outBid || ""} onChange={setFormData}/>
                        <Form.Text muted>New bids must be at least this much above the previous bid</Form.Text>
                    </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                    <Form.Group className="mb-3" controlId="formDraft_auction_multiple">
                        <Form.Label>Bid Multiple</Form.Label>
                        <Form.Control placeholder="Multiple $$"
                            value={formData.auctionSettings?.multiple || ""} onChange={setFormData}/>
                        <Form.Text muted>All bids must be a multiple of this value</Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            }
            { formData?.type === 'Auction' &&
            <Row>
                <Col xs={12} sm={6}>
                    <Form.Group className="mb-3" controlId="formDraft_auction_resetHours">
                        <Form.Label>Reset Hours</Form.Label>
                        <Form.Control placeholder="Hours"
                            value={formData.auctionSettings?.resetHours || ""} onChange={setFormData}/>
                        <Form.Text muted>Number of hours for someone to up the bid</Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            }
            <Row>
                <Col>
                    <Button variant="primary" style={{ marginBottom: "1rem" }} type="submit">Submit</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    { error.map( (e, idx) => <Alert key={idx} variant="danger">{ e }</Alert> )}
                </Col>
            </Row>
        </Container>
    </Form>;

    const noCreditsForm = <div>We are still in a beta state.  To create a new draft, please contact us
            at <a target="_blank" rel="noreferrer" href={"mailto:" + currentState.supportEmail}>{ currentState.supportEmail }</a>
        </div>;

    return (
        !user
        ? <div>Loading ...</div>
        : ( user.newDraftCredits > 0 ? newForm : noCreditsForm )
    )
};

export default SettingsForm;

