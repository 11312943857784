import React, { useContext } from 'react';
import UserContext from '../layouts/UserContext'
import HomePage from './HomePage'
import LandingPage from './LandingPage'

const Root = () => {
    const currentState = useContext(UserContext)

    if (!currentState) {
        return <div>Loading ...</div>
    }

    if (!currentState.user) {
        return <HomePage/>
    }

    return <LandingPage/>
};

export default Root;

