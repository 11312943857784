import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'

const DataTable = (props) => {
    const cols = props.headers?.length || (props.rows?.length > 0 ? props.rows[0].length : 1);

    return (
        <Table striped bordered hover responsive>
            <thead>
                { props.title &&
                <tr>
                    <td colSpan={cols} style={{ padding: 0 }}>
                        <Navbar bg="light" expand="sm" style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <Container fluid={true} style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                                <Navbar.Brand style={{ fontWeight: "bold" }}>{props.title}</Navbar.Brand>
                                { props.navLinks }
                            </Container>
                        </Navbar>
                    </td>
                </tr>
                }
                { props.headers &&
                <tr>
                    { props.headers?.map( (value, index) => <th key={index}>{value}</th> ) }
                </tr>
                }
            </thead>
            <tbody>
                {
                    (!props.hasOwnProperty('ready') || props.ready) ?
                        props.rows?.map( (row,rowindex) =>
                            <tr key={row.id}>
                                { row.data.map( (col, colindex) => <td key={colindex}>{col}</td> ) }
                            </tr>
                        )
                        : <tr><td colSpan={cols}>Loading ...</td></tr>
                }
            </tbody>
            { props.hideCount !== true &&
            <tfoot>
                <tr>
                    <td colSpan={cols}>
                        <div style={{ textAlign: "right" }}>Count: {props.rows?.length}</div>
                    </td>
                </tr>
            </tfoot>
            }
        </Table>
    )
};

export default DataTable;



