import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Amplify from 'aws-amplify';

const awsconfig = {
    Auth: {
        region: process.env.REACT_APP_DraftStackRegion,
        userPoolId: process.env.REACT_APP_DraftUserPoolId,
        userPoolWebClientId: process.env.REACT_APP_DraftUserPoolClientId,
        authenticationFlowType: 'USER_SRP_AUTH',
    },
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_DraftGraphQLAPIURL,
    aws_appsync_region: process.env.REACT_APP_DraftStackRegion,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
}

if (!awsconfig.Auth.region) {
    console.error("Environment variables not set");
}
Amplify.configure(awsconfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

