
const PickStatus = (props) => {
    const draft = props.draft
    const status = props.status;

    if (!draft) { return ""; }

    return (
        draft.status === "Running"
        ? <>
            { status.lastPick ? <div>Previous pick: { status.lastPick }</div> : "" }
            { status.onClock ? <div>On the clock: { status.onClock }</div> : "" }
            { status.onDeck ? <div>On deck: { status.onDeck }</div> : "" }
        </>
        : <div>Draft Status: {draft.status}</div>
    )
}

export default PickStatus;
