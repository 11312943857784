import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import { API, graphqlOperation } from 'aws-amplify';

//const Social = () => {
//    return (
//        <div className="col-lg-6 h-100 text-center text-lg-end my-auto">
//            <ul className="list-inline mb-0">
//                <li className="list-inline-item me-4">
//                    <a href="#!"><i className="bi-facebook fs-3"></i></a>
//                </li>
//                <li className="list-inline-item me-4">
//                    <a href="#!"><i className="bi-twitter fs-3"></i></a>
//                </li>
//                <li className="list-inline-item">
//                    <a href="#!"><i className="bi-instagram fs-3"></i></a>
//                </li>
//            </ul>
//        </div>
//    )
//}

const Footer = () => {
    const [showContactForm, setShowContactForm] = React.useState(false);
    const [showThanksForm, setShowThanksForm] = React.useState(false);

    const ThanksForm = () => {
        return <Modal show={showThanksForm} onHide={() => setShowThanksForm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Thank You!</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Thanks for reaching out.  We'll get back to you shortly.</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" type="button" onClick={() => setShowThanksForm(false)}>Close</Button>
                </Modal.Footer>
        </Modal>
    }

    const ContactForm = () => {
        const [error, setError] = React.useState([]);
        const [contactName, setContactName] = React.useState("");
        const [contactEmail, setContactEmail] = React.useState("");
        const [contactMessage, setContactMessage] = React.useState("");

        const sendContact = async (event) => {
            event.preventDefault();
            const errors = [];
            try {
                if (contactName.length === 0) { errors.push("Name is required") }
                if (contactEmail.length === 0) { errors.push("Email address is required") }
                if (contactEmail.length > 0 && !contactEmail.includes("@")) { errors.push("Email address is invalid") }
                if (contactMessage.length === 0) { errors.push("Message is required") }
                if (errors.length > 0) {
                    setError(errors);
                    return;
                }
                const contactUs = /* GraphQL */ `
                    mutation ContactUs($name: String!, $email: String!, $message: String!) {
                        ContactUs(name: $name, email: $email, message: $message)
                    }
                `;
                const result = await API.graphql(
                    graphqlOperation( contactUs, {
                        name: contactName,
                        email: contactEmail,
                        message: contactMessage
                    } )
                );
                console.log('Contact', result);
                setShowContactForm(false);
                setShowThanksForm(true);
            }
            catch (err) {
                console.log('Contact', err);
                setError([err.errors[0].message]);
            }
        };

        return <Modal show={showContactForm} onHide={() => setShowContactForm(false)}>
            <Form onSubmit={sendContact}>
                <Modal.Header closeButton>
                    <Modal.Title>Contact Us</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form.Group className="mb-3" controlId="contactName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" value={contactName}
                            onChange={(e) => {e.preventDefault(); setContactName(e.target.value)}}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="contactEmail">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control type="text" value={contactEmail}
                            onChange={(e) => {e.preventDefault(); setContactEmail(e.target.value)}}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="contactMessage">
                        <Form.Label>Message</Form.Label>
                        <Form.Control as="textarea" value={contactMessage} rows={5}
                            onChange={(e) => {e.preventDefault(); setContactMessage(e.target.value)}}/>
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" type="button" onClick={() => setShowContactForm(false)}>Close</Button>
                    <Button variant="primary" type="submit">Send</Button>
                    { error.map( (e, idx) => <Alert key={idx} style={{ flexBasis: "100%" }} variant="danger">{ e }</Alert> )}
                </Modal.Footer>
            </Form>
        </Modal>
    }

    return (
        <footer className="footer bg-light">
            <ContactForm/>
            <ThanksForm/>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <ul className="list-inline mb-2">
                            { false &&
                            <li className="list-inline-item"><a href="#!">About</a></li>
                            }
                            { false &&
                            <li className="list-inline-item">⋅</li>
                            }
                            <li className="list-inline-item">
                                <Button variant="link" onClick={ () => setShowContactForm(true) }>Contact Us</Button>
                            </li>
                        </ul>
                        <p className="text-muted small mb-4 mb-lg-0">&copy; MyFantasyToolbox 2022. All Rights Reserved.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
};

export default Footer;

