import React, { useContext } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown'
import UserContext from '../layouts/UserContext'

const AccountDropdown = (props) => {
    const currentState = useContext(UserContext)

    return (
        <NavDropdown title={currentState.user.given_name + " " + currentState.user.family_name}>
            <NavDropdown.Item href="#/account/settings">Account Settings</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={props.onSignOut}>Sign Out </NavDropdown.Item>
        </NavDropdown>
    )
};

export default AccountDropdown;


