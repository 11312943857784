import React, { useContext, useEffect } from 'react';
import UserContext from '../layouts/UserContext'
import MainLayout from '../layouts/MainLayout'
import Available from './Available'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { useParams } from "react-router-dom";
import { playerSort } from '../utils';
import PickTable from './PickTable';
import PlayerTable from './PlayerTable';
import PickStatus from './PickStatus';

const Team = () => {
    const params = useParams();
    const currentState = useContext(UserContext)
    const draft = currentState.currentDraft;
    const setCurrentDraftId = currentState.setCurrentDraftId;

    const [team, setTeam] = React.useState();
    const [readyPick, setReadyPick] = React.useState();

    const owned = team?.managerId === 'USER-' + currentState.user?.sub;

    useEffect(() => {
        if (draft?.id !== params.draftId) {
            setCurrentDraftId(params.draftId);
        }
    }, [draft?.id, params.draftId, setCurrentDraftId]);

    useEffect(() => {
        if (!draft) { setTeam(null); return; };
        const t = draft.teams.filter( t => t.id === params.teamId )[0];
        t.players = draft.players.filter( p => p.teamId === params.teamId );
        t.players.sort(playerSort);
        setTeam(t)
        console.log("TEAM", t);
    }, [draft, params.teamId, currentState.picksUpdated])

    if (!draft || !team) {
        return <div>Loading...</div>
    }

    return (
        <MainLayout>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>{team.name}</h1>
                    </Col>
                </Row>
                <Row xs={1} sm={2}>
                    <Col style={{ verticalAlign: "top"}}>
                        <div>Owned by: {team.manager ? (team.manager.firstName + ' ' + team.manager.lastName) : team.managerId}</div>
                        <div>Draft: {draft.name}</div>
                        <a href={"#/draft/" + params.draftId}>Back to Draft Page</a>
                    </Col>
                    <Col style={{ verticalAlign: "top"}}>
                        <PickStatus draft={draft} status={currentState.pickStatus}/>
                        { draft.showSalary ?
                            <div>
                                Team Salary: ${team.salary}
                                { team.pendingBidsTotal > 0 ? " (+$" + team.pendingBidsTotal.toFixed(2) + " pending)" : "" }
                            </div>
                            : ""
                        }
                        { draft.showSalary ?
                            <div>
                                Cap Space Remaining: ${(draft.auctionSettings.salaryCap - team.salary - team.pendingBidsTotal).toFixed(2)}
                            </div>
                            : ""
                        }
                        { draft.type === 'Auction' ?
                            <div>
                                Roster size: {team.rosterSize} of {draft.auctionSettings.maxRosterSize}
                                { team.injuredSize > 0 ? " (+" + team.injuredSize + " on IL)" : "" }
                                { team.pendingBids.length > 0 ? " (with " + team.pendingBids.length + " pending)" : "" }
                            </div>
                            : ""
                        }
                    </Col>
                </Row>
                <Row><Col><hr/></Col></Row>
                <Row>
                    <Col>
                        <Tabs defaultActiveKey={draft.type === 'Picks' ? "picks" : "roster"} className="mb-3">
                            { draft.type === "Picks" &&
                                <Tab eventKey="picks" title="Picks">
                                    <PickTable teamId={params.teamId} setReadyPick={setReadyPick} owned={owned}/>
                                    <hr/>
                                    <Available draftId={params.draftId} teamId={params.teamId} owned={owned} readyPick={readyPick}/>
                                </Tab>
                            }
                            <Tab eventKey="roster" title="Roster">
                                <PlayerTable players={team.players} includeSalary={draft.showSalary} />
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </MainLayout>
    )
};

export default Team;

