import React, { useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import UserContext from '../layouts/UserContext'
import { useNavigate } from "react-router-dom";

const AuthLayout = (props) => {
    const currentState = useContext(UserContext)
    const navigate = useNavigate();

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            currentState.setAuthState(nextAuthState);
            if (nextAuthState === AuthState.SignedIn && currentState.authState === AuthState.SignedIn) {
                currentState.setInAuth(false);

                const syncUser = async (newUser) => {
                    const syncUserMutation = /* GraphQL */ `
                      mutation SyncUser { SyncUser }
                    `;
                    const syncResults = await API.graphql(
                        graphqlOperation(syncUserMutation)
                    );
                    console.log("SYNC", syncResults);
                    currentState.setUser(newUser) // Triggers load of draft list, etc. Do after sync.
                    navigate('/');
                }
                syncUser(authData?.attributes || undefined);
            } else {
                currentState.setUser(authData?.attributes || undefined)
            }
        });
    }, [navigate, currentState]);

    if (currentState.authState === AuthState.SignedOut) {
        navigate('/');
    }

    return (
        <AmplifyAuthenticator usernameAlias="email" initialAuthState={currentState.authState}>
            <AmplifySignIn
                usernameAlias="email"
                headerText={"Sign In to " + currentState.applicationName}
                slot="sign-in"
                formFields={[
                    {
                        type: "email",
                        label: "Email Address",
                        placeholder: "Enter your email address",
                        inputProps: { required: true, autocomplete: "username" },
                    },
                    {
                        type: "password",
                        label: "Password",
                        placeholder: "Enter your password",
                        inputProps: { required: true, autocomplete: "password" },
                    },
                ]}
            ></AmplifySignIn>
            <AmplifySignUp
                usernameAlias="email"
                headerText={currentState.applicationName + " Sign Up"}
                slot="sign-up"
                formFields={[
                    {
                        type: "given_name",
                        label: "First Name",
                        placeholder: "Enter your first name",
                        inputProps: { required: true }
                    },
                    {
                        type: "family_name",
                        label: "Last Name",
                        placeholder: "Enter your last name",
                        inputProps: { required: true },
                    },
                    {
                        type: "email",
                        label: "Email Address",
                        placeholder: "Enter your email address",
                        inputProps: { required: true, autocomplete: "username" },
                    },
                    {
                        type: "password",
                        label: "Password",
                        placeholder: "Enter your password",
                        inputProps: { required: true, autocomplete: "new-password" },
                    },
                ]}
            ></AmplifySignUp>
        </AmplifyAuthenticator>
    )
}

export default AuthLayout;

