import React from 'react';
import { AuthState } from '@aws-amplify/ui-components';

const UserContext = React.createContext({
    user: undefined,
    setUser: () => {},
    authState: AuthState.SignedOut,
    setAuthState: () => {},
    inAuth: false,
    setInAuth: () => {},
    applicationName: "",
    drafts: [],
    reloadDrafts: () => {},
    currentDraft: undefined,
    setCurrentDraftId: () => {},
    reloadDraft: () => {},
    picksUpdated: undefined,
    setPicksUpdated: () => {},
    showAlert: false,
    setShowAlert: () => {},
    alertContents: "",
    setAlertContents: () => {},
    pickStatus: {},
    calculatePickStatus: () => {},
});

export default UserContext;

