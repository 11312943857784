import React, { useContext } from 'react';
import StatusBar from './StatusBar'
import Footer from './Footer'
import ToastContainer from 'react-bootstrap/ToastContainer'
import Toast from 'react-bootstrap/Toast'
import UserContext from './UserContext'

const MainLayout = (props) => {
    const currentState = useContext(UserContext);

    return (
        <>
            <ToastContainer className="p-3 position-fixed" position="top-end" style={{ zIndex: 9999 }}>
                <Toast bg="dark" show={currentState.showAlert} onClose={() => currentState.setShowAlert(false)}
                    autohide="true" delay="5000">
                    <Toast.Header>
                        <strong className="me-auto">Notification</strong>
                    </Toast.Header>
                    <Toast.Body className="text-white">{ currentState.alertContents }</Toast.Body>
                </Toast>
            </ToastContainer>
            <StatusBar/>
            <div className="bodySpacer">
                {props.children}
            </div>
            <Footer/>
        </>
    )
};

export default MainLayout;


