import React, { useContext, useState, useEffect } from 'react';
import UserContext from '../layouts/UserContext'
import DataTable from '../layouts/DataTable';
import Form from 'react-bootstrap/Form'

const PlayerTable = (props) => {
    const currentState = useContext(UserContext)
    const draft = currentState.currentDraft;

    const [playerSearch, setPlayerSearch] = useState("");
    const [playerSearchField, setPlayerSearchField] = useState("");
    const [playerSortField, setPlayerSortField] = useState("Last");
    const [playersToShow, setPlayersToShow] = useState();

    const sortFieldToName = (f) => {
        if (f === "Last") { return (a,b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName) }
        if (f === "First") { return (a,b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName) }
        if (f === "Team") { return (a,b) => a.team?.name.localeCompare(b.team?.name) || a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName) }
        return (a,b) => JSON.parse(a.other)[f]?.localeCompare(JSON.parse(b.other)[f]) || a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName);
    }

    useEffect(() => {
        console.log(playerSortField);
        switch(playerSearch) {
            case "":
                setPlayersToShow( props.players?.sort(sortFieldToName(playerSortField)) );
                break;
            default:
                setPlayersToShow( props.players.filter(
                        p =>  {
                            const matches = (f) => f?.toLowerCase().includes(playerSearch.toLowerCase());
                            const fieldFit = (f) => playerSearchField === "" || playerSearchField === f;
                            if (fieldFit("Last") && matches(p.lastName)) { return true; }
                            if (fieldFit("First") && matches(p.firstName)) { return true; }
                            if (fieldFit("Team") && matches(p.team?.name)) { return true; }
                            for (const col of draft?.playerColumns) {
                                if (fieldFit(col) && matches(JSON.parse(p.other)[col])) { return true; }
                            }
                            return false;
                        }
                    )?.sort( sortFieldToName(playerSortField) )
                )
                break;
        }
    }, [playerSearch, playerSearchField, playerSortField, props.players, draft?.playerColumns]);

    if (!draft) {
        return <div>Loading ...</div>;
    }

    const searchUpdated = (event) => {
        setPlayerSearch(event.target.value);
    };

    const searchFieldUpdated = (event) => {
        setPlayerSearchField(event.target.value);
    };

    const headers = ['Last', 'First']
        .concat(props.includeSalary ? ['Salary'] : [])
        .concat(props.includeTeam ? ['Team'] : [])
        .concat(draft.playerColumns || [])
        .concat(props.extraHeaders || []);
    const headersUI = headers.map(
        (h) => <span onClick={() => setPlayerSortField(h)}>{h} {h === playerSortField ? "v" : ""}</span>
    );
    const navLinks = [
        <Form key="search" className="d-flex">
            <Form.Select size="sm" value={playerSearchField}
                onChange={ searchFieldUpdated }>
                <option value="">All Fields</option>
                { headers.map( (h) => <option key={h} value={h}>{h}</option> ) }
            </Form.Select>
            <Form.Control size="sm" type="search" value={playerSearch}
                onChange={ searchUpdated }
                placeholder="Search" aria-label="Search" />
        </Form>
    ]
    const rows = playersToShow?.map( (player) => ({
        id: player.id,
        data: [
            player.lastName + (player.injured ? " (IL)" : ""),
            player.firstName
        ].concat(
            props.includeSalary ?
            [
                draft.type !== 'Auction' || player.team
                ?  '$' + parseFloat(player.salary).toFixed(2)
                : ""
            ]
            : []
        ).concat(
            props.includeTeam
            ? [<a href={"#/draft/" + draft.id + "/team/" + player.teamId}>{player.team?.name}</a>]
            : []
        ).concat(
            draft.playerColumns.map( pc => JSON.parse(player.other)[pc] || "" )
        ).concat(
            props.extraColumns?.map( xc => xc(player) ) || []
        )
    }));
    console.log(rows ? rows[0]?.data[0] : "NO ROWS");

    return <DataTable title={props.title || "Players"} navLinks={navLinks} headers={headersUI} rows={rows} ready={props.players}/>
};

export default PlayerTable;

