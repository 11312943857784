import React, { useContext, useEffect } from 'react';
import UserContext from '../layouts/UserContext'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import { API, graphqlOperation } from 'aws-amplify';

const CommissionerBulkUploadForm = () => {
    const currentState = useContext(UserContext)
    const draft = currentState.currentDraft;

    const [rawPlayers, setRawPlayers] = React.useState("");
    const [processedPlayers, setProcessedPlayers] = React.useState([]);
    const [playerWarnings, setPlayerWarnings] = React.useState([]);
    const [isSaving, setIsSaving] = React.useState(false);

    const columnSettingsReducer = (state, event) => {
        if (event.reset) { return event.data; }
        const hash = {}
        state.map( s => hash[s.num] = s );
        const num = event.target.id.split('_')[2];
        const key = event.target.id.split('_')[3];
        const old = hash[num] ?? {};
        old[key] = event.target.value;
        if (old.type !== 'MoreInfo') {
            old.label = '';
        }
        return Object.keys(hash).map( h => hash[h] );
    }
    const [columnSettings, setColumnSettings] = React.useReducer(columnSettingsReducer, []);
    const [columnWarnings, setColumnWarnings] = React.useState([]);

    const teamSettingsReducer = (state, event) => {
        if (event.reset) { return event.data; }
        const hash = {}
        state.map( s => hash[s.name] = s );
        const key = event.target.id.split('_')[3];
        hash[event.teamKey] = { ...(hash[event.teamKey] ?? {}), [key]: event.target.value };
        return Object.keys(hash).map( h => hash[h] );
    }
    const [teamSettings, setTeamSettings] = React.useReducer(teamSettingsReducer, []);
    const [teamWarnings, setTeamWarnings] = React.useState([]);

    const [canSubmit, setCanSubmit] = React.useState(false);

    useEffect(() => {
        if (rawPlayers && rawPlayers.trim().length > 0) {
            const warnings = [];
            const players = rawPlayers.split('\n').filter( p => p.trim().length > 0 ).map( p => p.split('\t') );

            const colCounts = [...new Set(players.map( p => p.length ))];
            colCounts.length > 1 && warnings.push("Records provided have inconsistent column counts");
            setProcessedPlayers(players);
            setPlayerWarnings(warnings);
        } else {
            setProcessedPlayers([]);
            setPlayerWarnings([]);
        }
    }, [rawPlayers])

    useEffect(() => {
        if (processedPlayers.length === 0) {
            setColumnSettings({reset: true, data: []});
            setTeamSettings({reset: true, data: []});
            setColumnWarnings([]);
            setTeamWarnings([]);
            return;
        }
        const initData = processedPlayers[0].map( (p,idx) => { return {num: idx, val: p }; } );
        setColumnSettings({reset: true, data: initData});
    }, [processedPlayers])

    useEffect(() => {
        if (processedPlayers.length === 0) { return; }
        const warnings = [];
        const previewPlayer = processedPlayers[0];
        const colTypes = columnSettings.map( c => c?.type );
        colTypes.filter(c => c).length < previewPlayer.length && warnings.push("All column types need to be set");

        const playerNameCols = colTypes.filter(c => c?.startsWith('PlayerName_'))
        playerNameCols.length === 0 && warnings.push("Must provide a player name");
        playerNameCols.length > 2 && warnings.push("Too many name columns provided");

        if (playerNameCols.length === 2 &&
            (playerNameCols.includes("PlayerName_FirstLast") || playerNameCols.includes("PlayerName_LastFirst"))) {
            warnings.push("Conflicting name columns detected");
        }

        let foundTeams = [];
        const teamNameCols = columnSettings.filter( k => k.type === "TeamName")
        teamNameCols.length > 1 && warnings.push("Too many team name columns provided");
        if (teamNameCols.length === 1) {
            foundTeams = [...new Set(processedPlayers.map(p => p[teamNameCols[0].num]))].sort();
            if (foundTeams.length > 25) {
                warnings.push("Found too many teams in the specified team column (max is 25)")
                foundTeams = [];
            }
        }
        if (teamSettings.length === 0 && foundTeams.length > 0) {
            const matchToExisting = t => {
                const match = draft.teams?.find( e => e.name.toLowerCase() === t.toLowerCase() );
                return match ? match.id : '';
            }
            const initTeamSettings = foundTeams.map( t => { return {name: t, action: matchToExisting(t)} } );
            setTeamSettings({reset: true, data: initTeamSettings});
        }
        if (foundTeams.length === 0) {
            setTeamSettings({reset: true, data: []});
        }
        setColumnWarnings(warnings);
    }, [processedPlayers, columnSettings, draft.teams, teamSettings.length])

    useEffect(() => {
        const warnings = [];
        const teamActions = teamSettings.map( t => t?.action );
        (teamSettings.length > 0 && teamActions.filter(t=>t).length !== teamSettings.length) && warnings.push("An action is required for all teams")

        const moreInfoColumns = columnSettings.filter( c => c?.type === 'MoreInfo' );
        const missingMoreInfoLabels = moreInfoColumns.filter(c => !c.label);
        missingMoreInfoLabels.length > 0 && warnings.push("All more info columns require a label value");
        setTeamWarnings(warnings);
    }, [columnSettings, teamSettings])

    useEffect(() => {
        setCanSubmit( processedPlayers.length && playerWarnings.length === 0 && columnWarnings.length === 0 && teamWarnings.length === 0);
    }, [processedPlayers, playerWarnings, columnWarnings, teamWarnings])

    const handleUpdateSubmit = async (event) => {
        event.preventDefault();
        console.log('Submit', event);
        console.log('Players', rawPlayers);
        console.log('Columns', columnSettings);
        console.log('Teams', teamSettings);

        const bulkPlayerMutation = /* GraphQL */ `
          mutation BulkPlayerInput($draftId: ID!, $data: BulkPlayerInputData!) {
            BulkPlayerInput(draftId: $draftId, data: $data)
          }
        `;
        try {
            setIsSaving(true);
            const result = await API.graphql(
                graphqlOperation( bulkPlayerMutation, {
                    draftId: draft.id,
                    data: {
                        playerData: rawPlayers,
                        columnData: columnSettings.map(c => { return {num: c.num, type: c.type, label: c.label}; } ),
                        teamData: teamSettings,
                    }
                } )
            );
            console.log('Bulk Player', result);
            currentState.reloadDraft(draft.id);
            setRawPlayers("");
            setProcessedPlayers([]);
            setPlayerWarnings([]);
            setColumnSettings({reset: true, data: []});
            setTeamSettings({reset: true, data: []});
            setColumnWarnings([]);
            setTeamWarnings([]);
            setCanSubmit(false);
        } catch (err) {
            console.log('Bulk Player', err);
        }
        finally {
            setIsSaving(false);
        }
    }

    return <Form onSubmit={handleUpdateSubmit}>
        <Table>
            <thead><tr><th>Player Data</th></tr></thead>
            <tbody>
                <tr>
                    <td>
                        <Form.Group className="mb-3" controlId="formDraft_playerData">
                            <Form.Control size="sm" as="textarea" value={rawPlayers} onChange={e => setRawPlayers(e.target.value)}/>
                            <Form.Text muted>
                                Paste data in CSV or TSV format (like from a spreadsheet)
                            </Form.Text>
                        </Form.Group>
                        { playerWarnings.length > 0 ?
                            <Alert variant="warning">{ playerWarnings.map( (w,idx) => <div key={idx}>{w}</div> ) }</Alert>
                            : null
                        }
                    </td>
                </tr>
            </tbody>
        </Table>
        { processedPlayers.length > 0 &&
        <Table>
            <thead>
                <tr><th>Player Columns</th><th>Column Type</th><th>More Info Label</th></tr>
            </thead>
            <tbody>
                { columnSettings.map( (colSet,idx) => {
                    return <tr key={idx}>
                        <td>{colSet.val}</td>
                        <td>
                            <Form.Group controlId={"formDraft_col_" + idx + "_type"}>
                                <Form.Select size="sm" value={colSet.type ?? ""} onChange={setColumnSettings}>
                                    <option value=""></option>
                                    <option value="PlayerName_First">First Name</option>
                                    <option value="PlayerName_Last">Last Name</option>
                                    <option value="PlayerName_FirstLast">First Name Last Name</option>
                                    <option value="PlayerName_LastFirst">Last Name, First Name</option>
                                    <option value="TeamName">Team Name</option>
                                    { draft.showSalary &&
                                    <option value="Salary">Salary</option>
                                    }
                                    <option value="MoreInfo">More Info</option>
                                    <option value="Ignore">Ignore</option>
                                </Form.Select>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId={"formDraft_col_" + idx + "_label"}>
                                <Form.Control size="sm" value={colSet.label ?? ""}
                                    disabled={colSet.type !== 'MoreInfo'}
                                    onChange={setColumnSettings}/>
                            </Form.Group>
                        </td>
                    </tr>
                } ) }
                <tr>
                    <td colSpan="3">
                        { columnWarnings.length > 0 ?
                            <Alert variant="warning">{ columnWarnings.map( (w,idx) => <div key={idx}>{w}</div> ) }</Alert>
                            : null
                        }
                    </td>
                </tr>
            </tbody>
        </Table>
        }
        { teamSettings.length > 0 &&
            <Table>
                <thead>
                    <tr><th>Team Name</th><th>Action</th></tr>
                </thead>
                <tbody>
                    { teamSettings.map( (val,idx) => {
                        return <tr key={val.name}>
                            <td>{val.name}</td>
                            <td>
                                <Form.Group controlId={"formDraft_team_" + idx + "_action"}>
                                    <Form.Select size="sm" value={val.action}
                                        onChange={(e) => { e.teamKey = val.name; setTeamSettings(e) }}>
                                        <option value=""></option>
                                        <option value="New">Create New</option>
                                        <option value="Ignore">Ignore (Free Agent)</option>
                                        { draft?.teams?.map( (t) => {
                                            return <option key={t.id} value={t.id}>Match - {t.name}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </td>
                        </tr>
                    } ) }
                    <tr>
                        <td colSpan="3">
                            { teamWarnings.length > 0 ?
                                <Alert variant="warning">{ teamWarnings.map( (w,idx) => <div key={idx}>{w}</div> ) }</Alert>
                                : null
                            }
                        </td>
                    </tr>
                </tbody>
            </Table>
        }
        <Table>
            <tbody>
                <tr>
                    { isSaving
                        ?
                        <td colSpan="3">Saving ...</td>
                        :
                        <td colSpan="3">
                            <Button variant="primary" type="submit" disabled={!canSubmit}>Submit</Button>
                            <span>&nbsp; { processedPlayers.length } players found.</span>
                        </td>
                    }
                </tr>
            </tbody>
        </Table>
    </Form>
};

export default CommissionerBulkUploadForm;

