import React, { useState, useEffect, useCallback } from 'react';
import UserContext from './layouts/UserContext';
import { AuthState } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import { HashRouter, Routes, Route } from "react-router-dom";
import Root from './components/Root';
import AuthLayout from './components/AuthLayout';
import Draft from './components/Draft';
import Team from './components/Team';
import AccountSettings from './components/AccountSettings';
import { API, graphqlOperation } from 'aws-amplify';
import { nameSort, playerSort } from './utils';
import Commissioner from './components/Commissioner';

//import './App.css';

const APPLICATION_NAME = "MyFantasyToolbox Draft";
const SUPPORT_EMAIL = "support@myfantasytoolbox.com";

const App = () => {
    const [user, setUser] = useState();
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertContents, setAlertContents] = React.useState(false);
    const [authState, setAuthState] = React.useState();
    const [inAuth, setInAuth] = React.useState();
    const [drafts, setDrafts] = React.useState();
    const [currentDraft, setCurrentDraft] = React.useState();
    const [picksUpdated, setPicksUpdated] = React.useState();
    const [pickStatus, setPickStatus] = React.useState({});

    const calculatePickStatus = useCallback(async (picks) => {
        const picked = picks.filter( p => p.playerId );
        const unpicked = picks.filter( p => !p.playerId );
        const lastPicked = picked ? picked[picked.length-1] : "";

        setPickStatus({
            lastPick: lastPicked
                ? lastPicked.player.firstName + " " + lastPicked.player.lastName + " by " + lastPicked.team.name
                : "",
            onClock: unpicked?.length > 0 ? unpicked[0].team.name : "",
            onDeck: unpicked?.length > 1 ? unpicked[1].team.name : ""
        });
    },[]);

    const getDraftsForUser = useCallback(async () => {
        const draftsQuery = /* GraphQL */ `
          query ListDraftsForUser {
            ListDraftsForUser {
              id
              name
              status
            }
          }
        `;
        if (!user) return;
        const lgs = await API.graphql(
            graphqlOperation(draftsQuery)
        );
        console.log('Drafts', lgs);
        lgs.data.ListDraftsForUser.sort(nameSort);
        setDrafts(lgs.data.ListDraftsForUser);
    },[user])

    useEffect(() => {
        getDraftsForUser();
    }, [user, getDraftsForUser])

    useEffect(() => {
        if (currentDraft?.id) {
            const onMakePick = /* GraphQL */ `
              subscription OnMakePick($draftId: ID!) {
                OnMakePick(draftId: $draftId) {
                  id
                  round
                  num
                  isNext
                  draftId
                  teamId
                  playerId
                }
              }
            `;
            const subscription = API.graphql(
                graphqlOperation(onMakePick, { draftId: currentDraft.id })
            ).subscribe({
                next: ({ provider, value }) => {
                    console.log('PICK UPDATED', provider, value);
                    setPicksUpdated(value.data.OnMakePick.id);
                    setAlertContents('A new pick has been made');
                    setShowAlert(true);
                },
                error: error => {
                    console.warn('PICK UPDATED', error);
                }
            });
            console.log(subscription);
            return () => subscription.unsubscribe();
        }
    }, [currentDraft?.id])

    const loadDraftById = useCallback(async (newDraftId) => {
        if (newDraftId === null) {
            setCurrentDraft(undefined);
            return;
        }
        const draftQuery = /* GraphQL */ `
          query GetDraftById($draftId: ID!) {
            GetDraftById(draftId: $draftId) {
              id
              name
              notes
              type
              status
              playerColumns
              auctionSettings {
                salaryCap
                maxRosterSize
                minBid
                maxBid
                outBid
                multiple
                resetHours
              }
              managerId
              manager {
                firstName
                lastName
                emailAddress
              }
              teams {
                id
                draftId
                name
                managerId
                manager {
                  firstName
                  lastName
                  emailAddress
                }
              }
              picks {
                id
                round
                num
                teamId
                team {
                  name
                }
                playerId
                player {
                  lastName
                  firstName
                  salary
                }
              }
              auctions {
                playerId
                player {
                  lastName
                  firstName
                  injured
                  other
                }
                teamId
                team {
                  id
                  name
                }
                amount
                bidTime
                endTime
                bids {
                  id
                  teamId
                  bidTime
                  endTime
                  amount
                }
              }
              players {
                id
                lastName
                firstName
                salary
                teamId
                team {
                  name
                }
                injured
                other
              }
            }
          }
        `;
        const lg = await API.graphql(
            graphqlOperation(draftQuery, {draftId: newDraftId})
        );
        console.log('Draft', lg);
        const now = new Date();
        const draft = lg.data.GetDraftById;
        draft.teams.sort(nameSort);
        draft.players.sort(playerSort);
        for( const team of draft.teams ) {
            team.salary = draft.players
                .filter( p => p.teamId === team.id )
                .reduce( (partialSum, p) => partialSum + (p.salary || 0), 0.0 )
                .toFixed(2);
            team.rosterSize = draft.players
                .filter( p => p.teamId === team.id && !p.injured )
                .length;
            team.injuredSize = draft.players
                .filter( p => p.teamId === team.id && p.injured )
                .length;
            team.pendingBids = draft.auctions
                .filter( a => a.teamId === team.id && (new Date(a.endTime)) > now )
                || [];
            team.pendingBidsTotal = team.pendingBids.reduce( (sum,b) => sum += b.amount, 0 );
        }
        calculatePickStatus(draft.picks);
        draft.showSalary = draft.type === "Auction";
        setCurrentDraft(draft);
    }, [calculatePickStatus]);

    const reloadDraft = useCallback(async () => {
        loadDraftById(currentDraft?.id);
    }, [currentDraft?.id, loadDraftById]);

    const reloadDrafts = async () => {
        getDraftsForUser();
    }

    useEffect(() => {
        if (currentDraft?.id) {
            const onPlaceBid = /* GraphQL */ `
              subscription OnPlaceBid($draftId: ID!) {
                OnPlaceBid(draftId: $draftId) {
                  id
                  draftId
                  teamId
                  playerId
                  amount
                }
              }
            `;
            const subscription = API.graphql(
                graphqlOperation(onPlaceBid, { draftId: currentDraft.id })
            ).subscribe({
                next: ({ provider, value }) => {
                    console.log('BID PLACED', provider, value);
                    reloadDraft();
                    setAlertContents('A bid has been placed');
                    setShowAlert(true);
                },
                error: error => {
                    console.warn('BID PLACED', error);
                }
            });
            console.log(subscription);
            return () => subscription.unsubscribe();
        }
    }, [currentDraft?.id, reloadDraft])

    useEffect(() => {
        async function onLoad() {
            try {
                await Auth.currentSession();
                const { attributes } = await Auth.currentAuthenticatedUser();
                setUser(attributes);
                setAuthState(AuthState.SignedIn);
                console.log("CurrentUser", attributes);
            }
            catch (e) {
                console.log("OnLoad", e);
                setUser(undefined);
                setAuthState(AuthState.SignedOut);
            }
            setInAuth(false);
        }
        onLoad();
    }, []);

    const currentState = {
        user: user,
        setUser: setUser,
        authState: authState,
        setAuthState: setAuthState,
        inAuth: inAuth,
        setInAuth: setInAuth,
        applicationName: APPLICATION_NAME,
        supportEmail: SUPPORT_EMAIL,
        drafts: drafts,
        reloadDrafts: reloadDrafts,
        currentDraft: currentDraft,
        setCurrentDraftId: loadDraftById,
        reloadDraft: reloadDraft,
        picksUpdated: picksUpdated,
        setPicksUpdated: setPicksUpdated,
        showAlert: showAlert,
        setShowAlert: setShowAlert,
        alertContents: alertContents,
        setAlertContents: setAlertContents,
        pickStatus: pickStatus,
        calculatePickStatus: calculatePickStatus,
    }

    return (
        <HashRouter>
            <UserContext.Provider value={currentState}>
                <Routes>
                    <Route exact path="/" element={<Root />} />
                    <Route path="/auth" element={<AuthLayout />} />
                    <Route path="/draft/:draftId" element={<Draft />} />
                    <Route path="/draft/:draftId/commissioner" element={<Commissioner/>} />
                    <Route path="/draft/:draftId/team/:teamId" element={<Team />} />
                    <Route path="/account/settings" element={<AccountSettings />} />
                    <Route path="*" element={<div>404</div>} />
                </Routes>
            </UserContext.Provider>
        </HashRouter>
    )
};

export default App;

