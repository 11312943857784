import React, { useContext } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown'
import UserContext from '../layouts/UserContext'

const DraftDropdown = (props) => {
    const currentState = useContext(UserContext)
    return (
        <NavDropdown title="My Drafts" id="basic-nav-dropdown">
            {currentState.drafts?.map((draft,index)=>{
                return <NavDropdown.Item key={draft.id} href={"#/draft/" + draft.id}>{draft.name}</NavDropdown.Item>
            })}
            {(currentState.drafts?.length > 0) && <NavDropdown.Divider />}
            <NavDropdown.Item href="#/draft/new">New Draft</NavDropdown.Item>
        </NavDropdown>
    )
};

export default DraftDropdown;

