import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import MainLayout from '../layouts/MainLayout'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Toast from 'react-bootstrap/Toast'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const AccountSettings = () => {
    const [isSaving, setIsSaving] = React.useState(false);
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState(false);
    const [user, setUser] = useState();
    const [emailOnPick, setEmailOnPick] = useState(false);

    useEffect(() => {
        const getUserSettingsQuery = /* GraphQL */ `
            query GetUserSettings {
                GetUserSettings {
                    id,
                    emailOnPick
                }
            }
        `;
        async function getUserSettings() {
            try {
                const getResult = await API.graphql(
                    graphqlOperation( getUserSettingsQuery, {} )
                );
                console.log('Get User Settings', getResult);
                setUser(getResult.data.GetUserSettings);
                setEmailOnPick(getResult.data.GetUserSettings.emailOnPick);
            }
            catch (err) {
                console.log('Get User Settings', err);
                setError(err.errors[0].message);
            }
        }
        getUserSettings();
    }, [])

    const updateSettings = async (event) => {
        event.preventDefault();
        try {
            setIsSaving(true);
            const updateUserSettings = /* GraphQL */ `
                mutation UpdateUserSettings($settings: UserSettingsInput!) {
                    UpdateUserSettings(settings: $settings)
                }
            `;
            const result = await API.graphql(
                graphqlOperation( updateUserSettings, {
                    settings: {
                        id: user.id,
                        emailOnPick: emailOnPick
                    }
                } )
            );
            console.log('Update Settings', result);
            setSuccess(true);
        }
        catch (err) {
            console.log('Update Settings', err);
            setError(err.errors[0].message);
        }
        finally {
            setIsSaving(false);
        }
    };

    return <MainLayout>
        <Container fluid={true}>
            <Row>
                <Col>
                    <h1>Account Settings</h1>
                </Col>
            </Row>
            <Row><Col><hr/></Col></Row>
            <Row>
                <Col>User ID: {user?.id}</Col>
            </Row>
            <Row><Col><br/></Col></Row>
            <Row>
                <Col>
                    <Form onSubmit={updateSettings}>
                        <Form.Group className="mb-3" controlId="formEmailOnPick">
                            <Form.Check type="switch" 
                                checked={emailOnPick}
                                onChange={(e) => setEmailOnPick(e.target.checked)}
                                label="Send me an email after each pick is made"/>
                        </Form.Group>
                        <Button variant="primary" type="submit" disabled={isSaving}>Save changes</Button>
                        <Alert show={error !== ""} variant="danger">{ error }</Alert>
                        <Toast onClose={() => setSuccess(false)} show={success} delay={3000} autohide
                            className="d-inline-block m-1" bg="success">
                            <Toast.Body className="text-white">
                                Settings saved successfully
                            </Toast.Body>
                        </Toast>
                    </Form>
                </Col>
            </Row>
        </Container>
    </MainLayout>
};

export default AccountSettings;

