import React, { useContext, useEffect } from 'react';
import UserContext from '../layouts/UserContext'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import { API, graphqlOperation } from 'aws-amplify';
import { validateIsPositiveInteger, duplicates } from '../utils';

const CommissionerInitPicksForm = () => {
    const currentState = useContext(UserContext)
    const draft = currentState.currentDraft;

    const initReducer = (state, event) => {
        if (event.reset) { return event.data; }
        switch (event.target.id) {
            case 'formDraft_init_round_count':
                return { ...state, rounds: event.target.value }
            case 'formDraft_init_serpentine':
                return { ...state, serpentine: event.target.checked }
            default:
                return { ...state, teams: { ...state.teams, [event.teamKey]: event.target.value } };
        }
    }
    const [initSettings, setInitSettings] = React.useReducer(initReducer, {rounds: "", serpentine: false, teams: []});
    const [initWarnings, setInitWarnings] = React.useState([]);
    const [canSubmitInit, setCanSubmitInit] = React.useState(false);

    const [isSaving, setIsSaving] = React.useState(false);

    useEffect(() => {
        const teams = {}
        draft?.teams.map( (t,idx) => teams[idx] = "" );
        setInitSettings({reset: true, data: {rounds: "", serpentine: false, teams: teams}});
    }, [draft])

    useEffect(() => {
        const warnings = [];

        const roundsIsValid = validateIsPositiveInteger(initSettings.rounds, 'Rounds', warnings);
        if (roundsIsValid && parseInt(initSettings.rounds) > 50) {
            warnings.push("Rounds value must be less than 50")
        }
        const teamValues = Object.keys(initSettings.teams).map(k => initSettings.teams[k]);
        if (teamValues.filter(t => t === "").length > 0) {
            warnings.push("All picks need an assigned team")
        }
        if (duplicates(teamValues).length > 0) {
            warnings.push("Detected team(s) chosen twice")
        }
        setInitWarnings(warnings);
    }, [initSettings])

    useEffect(() => {
        setCanSubmitInit( initWarnings.length === 0 );
    }, [initSettings, initWarnings])


    const handleInitSubmit = async (event) => {
        event.preventDefault();
        const setInitialPicksMutation = /* GraphQL */ `
            mutation SetInitialPicks($draftId: ID!, $data: InitialPicksData!) {
                SetInitialPicks(draftId: $draftId, data: $data)
            }
        `;
        try {
            setIsSaving(true);
            console.log("Init", initSettings);
            const result = await API.graphql(
                graphqlOperation( setInitialPicksMutation, {
                    draftId: draft.id,
                    data: {
                        rounds: initSettings.rounds,
                        serpentine: initSettings.serpentine,
                        teamIds: Object.keys(initSettings.teams).map(k => initSettings.teams[k])
                    }
                } )
            );
            console.log('Init Picks', result);
            currentState.reloadDraft(draft.id);
            setCanSubmitInit(false);
        }
        catch (err) {
            console.log('Picks Update', err);
        }
        finally {
            setIsSaving(false);
        }
    }

    return <Form onSubmit={handleInitSubmit}>
            <Table>
                <thead>
                    <tr>
                        <th>Settings</th>
                        <th>Initial Order</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Rounds</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Group style={{width: "50px"}} controlId="formDraft_init_round_count">
                                                <Form.Control size="sm" value={initSettings.rounds}
                                                    onChange={setInitSettings}/>
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <th>Serpentine?</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Group controlId="formDraft_init_serpentine">
                                                <Form.Check
                                                    type="checkbox"
                                                    value={initSettings.serpentine}
                                                    onChange={setInitSettings}
                                                />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td>
                            <table>
                                <tbody>
                                    { draft?.teams.map( (t,idx) =>
                                    <tr key={idx}>
                                        <td>{idx+1}:</td>
                                        <td>
                                            <Form.Group key={idx} style={{padding: "3px"}} controlId={"formDraft_init_" + idx + "_teamId"}>
                                                <Form.Select size="sm" value={initSettings.teams[idx]}
                                                    onChange={(e) => { e.teamKey = idx; setInitSettings(e) }}>
                                                    <option value=""></option>
                                                    { draft?.teams.map( (team) =>
                                                    <option key={team.id} value={team.id}>{team.name}</option>
                                                    )}
                                                </Form.Select>
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    )}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    { initWarnings.length > 0 &&
                        <tr>
                            <td colSpan="3">
                                <Alert variant="warning">{ initWarnings.map( (w,idx) => <div key={idx}>{w}</div> ) }</Alert>
                            </td>
                        </tr>
                    }
                </tbody>
            </Table>
            <Table>
                <tbody>
                    <tr>
                        { isSaving
                            ?
                                <td colSpan="3">Saving ...</td>
                                :
                                <td colSpan="3">
                                    <Button variant="primary" type="submit" disabled={!canSubmitInit}>Submit</Button>
                                </td>
                        }
                    </tr>
                </tbody>
            </Table>
        </Form>
};

export default CommissionerInitPicksForm;

